/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useLayoutEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Tooltip, Typography, useMediaQuery } from '@mui/material';

// project-imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

import { dispatch, useSelector } from 'store';
import useConfig from 'hooks/useConfig';
import { HORIZONTAL_MAX_ITEM } from 'config';

// types
import { NavItemType } from 'types/menu';
import { MenuOrientation } from 'types/config';
import useAuth from 'hooks/useAuth';
import { getWorkflows } from 'store/reducers/workflows';
import { FormattedMessage } from 'react-intl';
// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

function EllipsisTooltip({ text, maxWidth }: any) {
  return (
    <Tooltip title={text} placement="top">
      <Typography
        style={{
          maxWidth: maxWidth,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {text}
      </Typography>
    </Tooltip>
  );
}

const Navigation = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const userRoles = user != null ? user.roles : ['user'];

  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { selectedLocation } = useSelector((state) => state.organization);
  const { workflows } = useSelector((state) => state.workflow);

  const { menuOrientation } = useConfig();
  const { drawerOpen } = useSelector((state) => state.menu);

  const [selectedItems, setSelectedItems] = useState<string | undefined>('');
  const [selectedLevel, setSelectedLevel] = useState<number>(0);
  const [menuItems, setMenuItems] = useState<{ items: NavItemType[] }>({ items: [] });

  useLayoutEffect(() => {
    setMenuItems(menuItem);
    // eslint-disable-next-line
  }, [menuItem]);

  useEffect(() => {
    if (selectedLocation) {
      let newMenuItems = { ...menuItems } as any;
      // adding loading menu ..
      if (newMenuItems.items[0]?.children[4]?.children && newMenuItems.items[0]?.children[4]?.children?.[0]?.id !== 'loader') {
        newMenuItems.items[0].children[4].children = [
          {
            id: 'loader',
            disabled: true,
            title: <EllipsisTooltip text="Loading..." maxWidth={150} />,
            type: 'item',
            url: `/404`
          }
        ];
        setMenuItems(newMenuItems);
      }
      dispatch(getWorkflows(selectedLocation));
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (workflows.length) {
      const workflowUrls: any[] = [];
      workflows.forEach((workflow: any) => {
        workflowUrls.push({
          id: String(workflow.id),
          title: <EllipsisTooltip text={workflow.name} maxWidth={150} />,
          label: <FormattedMessage id={workflow.name} />,
          type: 'item',
          url: `/workflows/${workflow.id}`,
          isEllipse: true
        });
      });
      let newMenuItems = menuItems as any;
      if (newMenuItems.items?.[0]?.children?.[4]?.children?.[0]?.id === 'loader') {
        newMenuItems.items[0].children[4].children.splice(0, 1);
      }
      if (newMenuItems.items?.[0]?.children?.[4]?.children.length < 2) {
        newMenuItems.items[0].children[4].children = [...workflowUrls, ...newMenuItems.items[0].children[4].children];
        setMenuItems(newMenuItems);
      }
    }
  }, [workflows.length]);

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;
  let lastItemIndex = menuItems.items.length - 1;
  let remItems: NavItemType[] = [];
  let lastItemId: string;

  const hasCommonValue = (array1: any, array2: any) => {
    for (let value of array1) {
      if (array2?.includes(value)) {
        return true;
      }
    }
    return false;
  };

  if (lastItem && lastItem < menuItems.items.length) {
    lastItemId = menuItems.items[lastItem - 1].id!;
    lastItemIndex = lastItem - 1;
    remItems = menuItems.items
      .slice(lastItem - 1, menuItems.items.length)
      .filter((item) => hasCommonValue(userRoles, item.allowedRoles))
      .map((item) => ({
        title: item.title,
        elements: item.children,
        icon: item.icon
      }));
  }

  const navGroups = menuItems.items
    .slice(0, lastItemIndex + 1)
    .filter((item) => hasCommonValue(userRoles, item.allowedRoles))
    .map((item) => {
      switch (item.type) {
        case 'group':
          return (
            <NavGroup
              key={item.id}
              setSelectedItems={setSelectedItems}
              setSelectedLevel={setSelectedLevel}
              selectedLevel={selectedLevel}
              selectedItems={selectedItems}
              lastItem={lastItem!}
              remItems={remItems}
              lastItemId={lastItemId}
              item={item}
            />
          );
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Fix - Navigation Group
            </Typography>
          );
      }
    });

  return (
    <Box
      sx={{
        pt: drawerOpen ? (isHorizontal ? 0 : 2) : 0,
        '& > ul:first-of-type': { mt: 0 },
        display: isHorizontal ? { xs: 'block', lg: 'flex' } : 'block'
      }}
    >
      {navGroups}
    </Box>
  );
};

export default Navigation;
