/* eslint-disable react-hooks/exhaustive-deps */
// material-ui
import { Box, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import io from 'socket.io-client';

import Notification from './Notification';
import Profile from './Profile';

import useConfig from 'hooks/useConfig';
import DrawerHeader from 'layout/MainLayout/Drawer/DrawerHeader';

// type
import OrganizationSettings from 'components/Customization/OrganizationSettings';
import useAuth from 'hooks/useAuth';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'store';
import { getUnreadNotificationCount } from 'store/reducers/common';
import { MenuOrientation } from 'types/config';
import { convertMillisecondsToMinutes } from 'utils';
import ThemeModePage from './ThemeMode';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { menuOrientation } = useConfig();
  const { selectedLocation } = useSelector((state) => state.organization);
  const { user } = useAuth();
  const dispatch = useDispatch();
  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const isActive = useRef<any>(true);
  const startTime = useRef<any>(null);
  const timeTrackerInterval = useRef<any>(null);
  const activityTimeOut = useRef<any>(null);
  const socketConn = useRef<any>(null);
  // Get the current pathname as the key for local storage
  const location = useLocation();
  const { pathname } = location;

  const pageKey = pathname;

  // function to start tracking time
  const startTrackingTime = () => {
    isActive.current = true;
    startTime.current = new Date().getTime();
    trackTime();
  };

  // function to stop tracking time
  const stopTrackingTime = () => {
    trackTime();
    isActive.current = false;
  };

  // function to track time
  const trackTime = () => {
    if (isActive.current && startTime.current) {
      const totalTime = new Date().getTime() - startTime.current;
      const totalMins = convertMillisecondsToMinutes(totalTime);
      if (socketConn.current && totalMins > 0)
        socketConn.current.emit(
          'trackActivity',
          JSON.stringify({ userId: user?.id, totalTime: totalMins, pageName: pageKey?.split('/')?.[1] })
        );
      startTime.current = new Date().getTime();
    }
  };

  const updatePageVisit = async () => {
    if (socketConn.current)
      socketConn.current.emit(
        'updateUserPageVisitCount',
        JSON.stringify({ userId: user?.id, count: 1, pageName: pageKey?.split('/')?.[1] })
      );
  };

  useEffect(() => {
    startTrackingTime();
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        startTrackingTime();
      } else {
        stopTrackingTime();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [startTime.current, pageKey]);

  useEffect(() => {
    if (pageKey) {
      updatePageVisit();
    }
  }, [pageKey]);

  useEffect(() => {
    const handleActivity = () => {
      if (!isActive.current) {
        startTrackingTime();
      }
      if (activityTimeOut.current) clearTimeout(activityTimeOut.current);
      activityTimeOut.current = setTimeout(() => {
        stopTrackingTime();
      }, 1000 * 60 * 5); // 5 minute idal intervel after that user will marked as inactive
    };

    handleActivity();

    // Reset activity timer on any user interaction
    window.addEventListener('click', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('scroll', handleActivity);

    if (timeTrackerInterval.current) clearInterval(timeTrackerInterval.current);
    timeTrackerInterval.current = setInterval(() => {
      trackTime();
    }, 1000 * 60 * 5); // updating total activity time in every 5 min

    return () => {
      trackTime();
      window.removeEventListener('click', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('scroll', handleActivity);
      clearInterval(timeTrackerInterval.current);
    };
  }, [startTime, pageKey]);

  useEffect(() => {
    if (selectedLocation) {
      if (socketConn.current) socketConn.current.disconnect();
      const socket = io(`${process.env.REACT_APP_SOCKET_URL}?locationId=${selectedLocation}&type=location&userId=${user?.id}`, {
        reconnectionDelayMax: 10000,
        transports: ['websocket']
      });
      socketConn.current = socket;
      socket.on('notification_receive', (data: string) => {
        const notification = JSON.parse(data);
        if (notification) {
          dispatch(getUnreadNotificationCount(selectedLocation));
        }
      });

      socket.on('notification_count_update', (data: string) => {
        dispatch(getUnreadNotificationCount(selectedLocation));
      });

      socket.on('new_message_receive', (data: string) => {
        dispatch(getUnreadNotificationCount(selectedLocation));
      });
    }
  }, [selectedLocation]);

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && <DrawerHeader open={true} />}
      {!downLG && <Box sx={{ width: '100%', ml: { xs: 0, md: 2 } }} />}
      {!downLG && <OrganizationSettings />}
      {downLG ? (
        <Box display="flex">
          <ThemeModePage />
          <Notification />
          <Profile />
        </Box>
      ) : (
        <>
          <ThemeModePage />
          <Notification />
          <Profile />
        </>
      )}
      {downLG && <OrganizationSettings />}
      {/* {downLG && <MobileSection />} */}
    </>
  );
};

export default HeaderContent;
