// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// types
import { UserDataStateProps } from 'types/common';
import { enqueueSnackbar } from 'notistack';
import { errorSnackConfig, succesSnackConfig } from '../../config';
import { calculatePastTime } from 'utils';

const settingsKey = ['notification'] as const;

export type SettingsKeyInterface = (typeof settingsKey)[number];

const initialState: UserDataStateProps = {
  error: null,
  loading: false,
  usersData: [],
  customerCount: 0,
  users: [],
  loadingUsers: false,
  locationMeterics: {},
  settings: null,
  unsubsbeUsers: [],
  loadingUnsubsbeUsers: false,
  unsubsbeUsersCount: 0,
  loadingNotification: false,
  notifications: [],
  archiveNotifications: [],
  unreadNotificationCount: 0,
  totalAlertCount: 0,
  totalUnreadCount: 0,
  loadingUnreadMessageNotification: false,
  unreadMessageNotification: [],
  openDirectMessageModal: false,
  directMessageCustomerId: '',
  directMessageCustomer: null,
  openResponseEditModal: false,
  responseEditData: null
};

// ==============================|| SLICE - CHAT ||============================== //

const UsersData = createSlice({
  name: 'common',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.loadingUsers = false;
    },
    getUserData(state) {
      state.loading = true;
    },

    getUserDataSuccess(state, action) {
      state.usersData = action.payload.customers ?? [];
      state.customerCount = action.payload.count ?? 0;
      state.loading = false;
    },
    getLocationMetericsSuccess(state, action) {
      state.locationMeterics = action.payload;
    },
    getSettingsSuccess(state, action) {
      state.settings = action.payload;
    },
    getUnsubscribeUsers(state) {
      state.loadingUnsubsbeUsers = true;
    },
    getUnsubscribeUsersSuccess(state, action) {
      state.unsubsbeUsers = action.payload.customers ?? [];
      state.unsubsbeUsersCount = action.payload.count ?? 0;
      state.loadingUnsubsbeUsers = false;
    },

    getUserNotification(state) {
      state.loadingNotification = true;
      state.notifications = [];
    },
    getUserNotificationSuccess(state, action) {
      state.notifications = action.payload?.notifications ?? [];
      state.loadingNotification = false;
    },
    getUserArchiveNotificationSuccess(state, action) {
      state.archiveNotifications = action.payload?.notifications ?? [];
    },
    getUnreadNotificationCountSuccess(state, action) {
      state.unreadNotificationCount = action.payload?.totalAlertCount + action.payload?.totalUnreadCount ?? 0;
      state.totalAlertCount = action.payload?.totalAlertCount;
      state.totalUnreadCount = action.payload?.totalUnreadCount;
    },
    getUnreadMessageNotification(state) {
      state.loadingUnreadMessageNotification = true;
      state.unreadMessageNotification = [];
    },
    resetNotificationLists(state) {
      state.unreadMessageNotification = [];
      state.archiveNotifications = [];
      state.notifications = [];
    },
    getUnreadMessageNotificationSuccess(state, action) {
      state.unreadMessageNotification = action.payload ?? [];
      state.loadingUnreadMessageNotification = false;
    },
    handleOpenDirectMesssageModal(state, action) {
      state.openDirectMessageModal = action.payload.open;
      state.directMessageCustomerId = action.payload.customerId;
    },
    getCustomerSuccess(state, action) {
      state.directMessageCustomer = action.payload;
    },
    handleCloseDirectMesssageModal(state) {
      state.openDirectMessageModal = false;
      state.directMessageCustomerId = '';
      state.directMessageCustomer = null;
    },
    handleEditResponse(state, action) {
      state.openResponseEditModal = action.payload.open;
      state.responseEditData = action.payload.data;
    },
    resetCommonStats(state) {
      state.usersData = [];
    }
  }
});

// Reducer
export default UsersData.reducer;

export const {
  handleOpenDirectMesssageModal,
  handleCloseDirectMesssageModal,
  resetNotificationLists,
  handleEditResponse,
  resetCommonStats
} = UsersData.actions;
export function getUserTables({ locationId, offset = 0, limit = 10, order = JSON.stringify({ riskLevel: 'ASC' }), ...rest }: any) {
  return async () => {
    try {
      dispatch(UsersData.actions.getUserData());
      let filterParams = '';
      Object.entries(rest).forEach(([key, value]) => {
        if (key === 'conversation_customerLastMessage_createdAt' && value) {
          filterParams += `&${key}=${calculatePastTime(value)}`;
        } else {
          filterParams += `&${key}=${Array.isArray(value) ? value?.filter((d) => !!d || typeof d === 'number') : value}`;
        }
      });
      const response = await axios.get(
        `/customers?locationId=${locationId}&offset=${offset}&limit=${limit}&order=${order}&includeWorkflowStats=true${filterParams}`
      );
      dispatch(UsersData.actions.getUserDataSuccess(response.data.payload));
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to fetch customers.', errorSnackConfig as any);
      dispatch(UsersData.actions.hasError(error));
    }
  };
}

export function getWorkflowMetricsByLocation(locationId: string) {
  return async () => {
    try {
      const response = await axios.get(`/locations/${locationId}/getWorkflowsMetricsByLocation`);
      dispatch(UsersData.actions.getLocationMetericsSuccess(response.data.payload || {}));
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to load workflow metrics.', errorSnackConfig as any);
    }
  };
}

export function createUpdateSettings(data: { values: any; locationId: string; settingKey: SettingsKeyInterface }) {
  return async () => {
    try {
      await axios.put(`/setting`, data as any);
      enqueueSnackbar('Settings updated successfully.', succesSnackConfig as any);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to update Settings.', errorSnackConfig as any);
    }
  };
}

export function getSettings(locationId: string) {
  return async () => {
    try {
      const response = await axios.get(`/setting?locationId=${locationId}`);
      dispatch(UsersData.actions.getSettingsSuccess(response.data.payload || null));
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to load user Settings.', errorSnackConfig as any);
    }
  };
}

export function getUnSubsCribeUsers({
  locationId,
  offset = 0,
  limit = 10,
  order = JSON.stringify({ dischargeDate: 'DESC' }),
  ...rest
}: any) {
  return async () => {
    try {
      dispatch(UsersData.actions.getUnsubscribeUsers());
      let filterParams = '';
      Object.entries(rest).forEach(([key, value]) => {
        if (key === 'conversation_customerLastMessage_createdAt' && value) {
          filterParams += `&${key}=${calculatePastTime(value)}`;
        } else {
          filterParams += `&${key}=${value}`;
        }
      });
      const response = await axios.get(
        `/customers?locationId=${locationId}&offset=${offset}&limit=${limit}&order=${order}&isUnsubscribed=true${filterParams}`
      );
      dispatch(UsersData.actions.getUnsubscribeUsersSuccess(response.data.payload));
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to fetch customers.', errorSnackConfig as any);
      dispatch(UsersData.actions.hasError(error));
    }
  };
}

export function getNotifications({ locationId, offset = 0, limit = 6 }: any) {
  return async () => {
    try {
      dispatch(UsersData.actions.getUserNotification());
      const response = await axios.get(`/notifications/${locationId}?offset=${offset}&limit=${limit}&isArchived=false`);
      dispatch(UsersData.actions.getUserNotificationSuccess(response.data.payload));
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to fetch customers.', errorSnackConfig as any);
      dispatch(UsersData.actions.hasError(error));
    }
  };
}

export function getArchiveNotifications({ locationId, offset = 0, limit = 6 }: any) {
  return async () => {
    try {
      const response = await axios.get(`/notifications/${locationId}?offset=${offset}&limit=${limit}&isArchived=true`);
      dispatch(UsersData.actions.getUserArchiveNotificationSuccess(response.data.payload));
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to fetch customers.', errorSnackConfig as any);
      dispatch(UsersData.actions.hasError(error));
    }
  };
}

export function archiveNotification(data: any) {
  return async () => {
    try {
      await axios.post(`/notifications/archiveAlert`, data as any);
      enqueueSnackbar('Archive successfully.', succesSnackConfig as any);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to update Settings.', errorSnackConfig as any);
    }
  };
}

export function markNotificationRead(notificationId: any) {
  return async () => {
    try {
      await axios.put(`/notifications/${notificationId}`);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to update Settings.', errorSnackConfig as any);
    }
  };
}

export function archiveAllAlert(data: any) {
  return async () => {
    try {
      await axios.post(`/notifications/archiveAllAlert`, data);
      enqueueSnackbar('Archived successfully.', succesSnackConfig as any);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to update Settings.', errorSnackConfig as any);
    }
  };
}

export function getUnreadNotificationCount(locationId: any) {
  return async () => {
    try {
      const response = await axios.get(`/notifications/${locationId}/getLocationAllNotificationCount`);
      dispatch(UsersData.actions.getUnreadNotificationCountSuccess(response.data.payload));
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to update Settings.', errorSnackConfig as any);
    }
  };
}

export function geUnreadMessageNotifications({ locationId, offset = 0, limit = 6 }: any) {
  return async () => {
    try {
      dispatch(UsersData.actions.getUnreadMessageNotification());
      const response = await axios.get(`/notifications/${locationId}/getLocationUnreadNotifications?offset=${offset}&limit=${limit}`);
      dispatch(UsersData.actions.getUnreadMessageNotificationSuccess(response.data.payload));
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to fetch customers.', errorSnackConfig as any);
      dispatch(UsersData.actions.hasError(error));
    }
  };
}

export function getCustomer(customerId: string) {
  return async () => {
    try {
      const response = await axios.get(`/customers/${customerId}`);
      dispatch(UsersData.actions.getCustomerSuccess(response.data.payload));
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to fetch customer.', errorSnackConfig as any);
      dispatch(UsersData.actions.hasError(error));
    }
  };
}

export function updateCustomer(customerId: string, data: any) {
  return async () => {
    try {
      const response = await axios.put(`/customers/${customerId}`, data);
      return response.data.payload;
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to update customer.', errorSnackConfig as any);
      dispatch(UsersData.actions.hasError(error));
    }
  };
}

export function createCustomer(data: any) {
  return async () => {
    try {
      const response = await axios.post(`/customers`, data);
      return response.data.payload;
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to add customer.', errorSnackConfig as any);
      dispatch(UsersData.actions.hasError(error));
    }
  };
}

export function deleteCustomer(customerId: string) {
  return async () => {
    try {
      const response = await axios.delete(`/customers/${customerId}`);
      return response.data;
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to update customer.', errorSnackConfig as any);
      dispatch(UsersData.actions.hasError(error));
    }
  };
}
