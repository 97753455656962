import { useMediaQuery, useTheme } from '@mui/material';

// ==============================|| OVERRIDES - TABLE ROW ||============================== //

export default function TableRow() {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-of-type': {
            '& .MuiTableCell-root': {
              borderBottom: 'none'
            }
          },
          '& .MuiTableCell-root': {
            '&:last-of-type': {
              paddingRight: matchDownSM ? 10 : 24
            },
            '&:first-of-type': {
              paddingLeft: matchDownSM ? 10 : 24
            }
          }
        }
      }
    }
  };
}
