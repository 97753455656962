/* eslint-disable react-hooks/exhaustive-deps */
import { SyntheticEvent, useEffect, useRef, useState } from 'react';

// material-ui
import {
  Badge,
  Box,
  CircularProgress,
  ClickAwayListener,
  Link,
  Paper,
  Popper,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project-imports
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';
import MainCard from 'components/MainCard';

// assets
import { ArrowCircleRight2, Edit, Notification } from 'iconsax-react';

// types
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'store';
import {
  archiveAllAlert,
  archiveNotification,
  geUnreadMessageNotifications,
  getArchiveNotifications,
  getNotifications,
  getUnreadNotificationCount,
  handleOpenDirectMesssageModal,
  resetNotificationLists
} from 'store/reducers/common';
import { ThemeMode } from 'types/config';
import NewNotificationTab from './Notification/alertNotificationTab';
import UnreadMessageNotificationTab from './Notification/unreadMessageNotificationTab';
import trophy from 'assets/images/trophy.png';
import { Chip } from '@mui/material';

// types
interface TabPanelProps {
  children?: any;
  dir?: string;
  index: number;
  value: number;
}

// tab panel wrapper
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
      sx={{ p: { xs: 0, lg: 1 } }}
    >
      {value === index && children}
    </Box>
  );
}

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const NotificationPage = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const anchorRef = useRef<any>(null);
  // const [read] = useState(0);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const data = useRef<any[]>([]);
  const { notifications, archiveNotifications, unreadMessageNotification, unreadNotificationCount, totalAlertCount, totalUnreadCount } =
    useSelector((state) => state.common);
  const { selectedLocation } = useSelector((state) => state.organization);
  const [value, setValue] = useState(0);
  const handleToggle = () => {
    dispatch(resetNotificationLists());
    setOpen((prevOpen) => !prevOpen);
  };

  const params = useRef<any>({
    limit: 4,
    offset: 0
  });

  const loadUnreadMessageNotification = () => {
    dispatch(geUnreadMessageNotifications({ locationId: selectedLocation, ...params.current })).then(() => {
      setLoading(false);
      setLoadingMore(false);
    });
  };

  const loadAlerts = () => {
    dispatch(getNotifications({ locationId: selectedLocation, ...params.current })).then(() => {
      setLoading(false);
      setLoadingMore(false);
    });
  };

  const loadArchiveAlerts = () => {
    dispatch(getArchiveNotifications({ locationId: selectedLocation, ...params.current })).then(() => {
      setLoading(false);
      setLoadingMore(false);
    });
  };

  useEffect(() => {
    if (open && selectedLocation) {
      params.current = {
        limit: 4,
        offset: 0
      };
      setLoading(true);
      data.current = [];
      dispatch(resetNotificationLists());
      if (value === 0) {
        loadUnreadMessageNotification();
      } else if (value === 1) {
        loadAlerts();
      } else {
        loadArchiveAlerts();
      }
    }
  }, [open, value, selectedLocation]);

  const handleArchiveAlert = (notification: any) => {
    dispatch(archiveNotification({ notificationId: notification?.id, isArchive: true })).then(() => {
      dispatch(getUnreadNotificationCount(selectedLocation));
    });
  };

  const handleClose = (event?: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event?.target)) {
      return;
    }
    params.current = {
      limit: 4,
      offset: 0
    };
    setOpen(false);
    setLoading(true);
    dispatch(getUnreadNotificationCount(selectedLocation));
    dispatch(resetNotificationLists());
    data.current = [];
  };

  function a11yProps(index: number) {
    return {
      id: `notification-tab-${index}`,
      'aria-controls': `profile-tabpanel-${index}`
    };
  }

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    params.current = {
      limit: 4,
      offset: 0
    };
    data.current = [];
    setLoading(true);
    dispatch(resetNotificationLists());
    setValue(newValue);
  };

  const handleArchiveAllAlerts = () => {
    dispatch(archiveAllAlert({ locationId: selectedLocation })).then(() => {
      params.current = {
        limit: 4,
        offset: 0
      };
      data.current = [];
      if (value === 1) {
        loadAlerts();
      } else {
        loadArchiveAlerts();
      }
      dispatch(getUnreadNotificationCount(selectedLocation));
    });
  };

  const loadMore = () => {
    setLoadingMore(true);
    params.current = {
      limit: 4,
      offset: params.current.limit + params.current.offset
    };
    if (value === 0) {
      loadUnreadMessageNotification();
    } else if (value === 1) {
      loadAlerts();
    } else {
      loadArchiveAlerts();
    }
  };

  useEffect(() => {
    if (value === 0 && open) {
      data.current = [...data.current, ...unreadMessageNotification];
    } else if (value === 1 && open) {
      data.current = [...data.current, ...notifications];
    } else {
      data.current = [...data.current, ...archiveNotifications];
    }
  }, [open, value, notifications?.[0]?.id, archiveNotifications?.[0]?.id, unreadMessageNotification?.[0]?.conversation_id]);

  const handleOpenDirectMessage = (customer: any) => {
    dispatch(handleOpenDirectMesssageModal({ open: true, customerId: customer.customer_id }));
    handleClose();
  };
  const loader = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </Box>
  );

  useEffect(() => {
    if (selectedLocation) {
      dispatch(getUnreadNotificationCount(selectedLocation));
    }
  }, [selectedLocation]);

  const handleNotificationClick = (notification: any) => {
    dispatch(handleOpenDirectMesssageModal({ open: true, customerId: notification.customer.id }));
    handleArchiveAlert(notification);
    handleClose();
  };

  const handleViewAll = () => {
    navigate('/chat/?filter=unread');
    handleClose();
  };

  const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? 'secondary.200' : 'secondary.200';
  const iconBackColor = theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'secondary.100';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.5 }}>
      <IconButton
        color="secondary"
        variant="light"
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        size="large"
        sx={{ color: 'secondary.main', bgcolor: open ? iconBackColorOpen : iconBackColor, p: 1 }}
      >
        <Badge
          badgeContent={unreadNotificationCount as number}
          color="error"
          sx={{ '& .MuiBadge-badge': { top: 2, right: 4, background: '#C33B3B' } }}
        >
          <Notification variant="Bold" />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom-start' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        sx={{
          zIndex: 999,
          width: { xs: '88%', md: 'auto' }
        }}
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            type="grow"
            position={matchesXs ? 'top-right' : 'top-right'}
            sx={{ overflow: 'hidden' }}
            in={open}
            {...TransitionProps}
          >
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                borderRadius: 1.5,
                width: '100%',
                minWidth: 500,
                maxWidth: 500,
                [theme.breakpoints.down('md')]: {
                  minWidth: '100%',
                  maxWidth: '100%'
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard elevation={0} border={false} contentSX={{ padding: '14px' }}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h5">Notifications</Typography>
                    {value === 0 && (
                      <Link
                        href="#"
                        variant="h6"
                        color="primary"
                        style={{ textDecoration: 'none' }}
                        display={'flex'}
                        onClick={handleViewAll}
                      >
                        <ArrowCircleRight2 size="20" />
                        &nbsp; View all
                      </Link>
                    )}

                    {value === 1 && (
                      <Link href="#" variant="h6" color="primary" style={{ textDecoration: 'none' }} onClick={handleArchiveAllAlerts}>
                        Archive all
                      </Link>
                    )}
                  </Stack>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                      variant="fullWidth"
                      value={value}
                      onChange={handleChange}
                      aria-label="profile tabs"
                      sx={{
                        minHeight: '30px',
                        '& .MuiChip-root': {
                          mb: 0.5,
                          fontSize: '10px'
                        }
                      }}
                    >
                      <Tab
                        sx={{
                          minHeight: '30px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: { xs: '10px' }
                        }}
                        label={
                          <div>
                            Inbox &nbsp;
                            <Chip
                              label={totalUnreadCount as number}
                              sx={{
                                background: '#C33B3B',
                                fontSize: '12px',
                                color: '#fff',
                                minWidth: 18,
                                height: 16,
                                '& .MuiChip-label': {
                                  px: 0.5
                                }
                              }}
                            />
                          </div>
                        }
                        {...a11yProps(0)}
                      />
                      <Tab
                        sx={{
                          minHeight: '30px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: { xs: '10px' }
                        }}
                        label={
                          <div>
                            Alerts &nbsp;
                            <Chip
                              label={totalAlertCount as number}
                              color="error"
                              sx={{
                                background: '#C33B3B',
                                fontSize: '12px',
                                minWidth: 18,
                                height: 16,
                                '& .MuiChip-label': {
                                  px: 0.5
                                }
                              }}
                            />
                          </div>
                        }
                        {...a11yProps(1)}
                      />
                      <Tab
                        sx={{
                          minHeight: '30px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: { xs: '8px' }
                        }}
                        // icon={<ArchiveTick size={18} variant="Bold" style={{ marginBottom: 0, marginRight: '10px' }} />}
                        label="Archived"
                        {...a11yProps(2)}
                      />
                    </Tabs>
                  </Box>
                  {/* unread message notification */}
                  <TabPanel value={value} index={0} dir={theme.direction}>
                    {loading ? (
                      loader()
                    ) : (
                      <UnreadMessageNotificationTab
                        data={data.current}
                        onClick={handleOpenDirectMessage}
                        onLoadMore={loadMore}
                        loadingMore={loadingMore}
                      />
                    )}
                    {!loading && data.current?.length === 0 ? (
                      <div className="card-trophy">
                        <img src={trophy} alt="trophy" />
                        <Typography variant="h2" m={0} p={0}>
                          You've done it!
                        </Typography>
                        <Typography variant="body1" m={0} p={0}>
                          You have no unread messages.
                        </Typography>
                      </div>
                    ) : null}
                    {loadingMore && loader()}
                  </TabPanel>
                  {/* alert notifications */}
                  <TabPanel value={value} index={1} dir={theme.direction}>
                    {loading ? (
                      loader()
                    ) : (
                      <NewNotificationTab
                        data={data.current}
                        onClick={handleNotificationClick}
                        onLoadMore={loadMore}
                        loadingMore={loadingMore}
                      />
                    )}
                    {!loading && data.current?.length === 0 ? (
                      <div className="card-trophy">
                        <img src={trophy} alt="trophy" />
                        <Typography variant="h2" m={0} p={0}>
                          You're all caught up!
                        </Typography>
                        <Typography variant="body1" m={0} p={0}>
                          You have no unread alerts.
                        </Typography>
                      </div>
                    ) : null}
                    {loadingMore && loader()}
                  </TabPanel>
                  {/* archive notifications */}
                  <TabPanel value={value} index={2} dir={theme.direction}>
                    {loading ? (
                      loader()
                    ) : (
                      <NewNotificationTab data={data.current} onClick={() => {}} onLoadMore={loadMore} loadingMore={loadingMore} />
                    )}
                    {!loading && data.current?.length === 0 ? (
                      <Typography variant="body1" align="center">
                        No notification found
                      </Typography>
                    ) : null}
                    {loadingMore && loader()}
                  </TabPanel>
                  <Link
                    href="#"
                    variant="body1"
                    display={'flex'}
                    mt={2}
                    sx={{ mt: { xs: 0 } }}
                    fontWeight={500}
                    style={{ textDecoration: 'none' }}
                    color="#999999"
                    onClick={() => {
                      navigate('/profiles/settings/notification');
                      handleClose();
                    }}
                  >
                    <Edit size="20" /> &nbsp; Edit notifications
                  </Link>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default NotificationPage;
