/* eslint-disable react-hooks/exhaustive-deps */
// material-ui
import { List, ListItemAvatar, ListItemButton, ListItemText, Stack, Typography, useTheme } from '@mui/material';
import Avatar from 'components/@extended/Avatar';
import TimeAgo from 'components/TimeAgo';
import { ArrowRight, CallSlash, Danger, Flag } from 'iconsax-react';
import { useEffect, useRef } from 'react';
import { showStatus } from 'utils/react-table';

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none'
};

const NewNotificationTab = ({ data, onClick, onLoadMore, loadingMore }: any) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const debounceTimeout = useRef<any>(null);

  const renderNotificationIcon = (type: any) => {
    switch (type) {
      case 'message failed':
        return <CallSlash size={20} variant="Bold" />;
      case 'high risk':
        return <Flag size={20} variant="Bold" />;
      default:
        return <Danger size={20} variant="Bold" />;
    }
  };

  useEffect(() => {
    // Function to fetch data, here simulate fetching more data
    const fetchData = () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => onLoadMore(), 300);
    };

    const handleScroll = () => {
      if (
        containerRef.current &&
        containerRef.current.scrollTop + containerRef.current.clientHeight >= containerRef.current.scrollHeight - 4 &&
        !loadingMore
      ) {
        fetchData();
      }
    };

    containerRef.current?.addEventListener('scroll', handleScroll);

    return () => {
      containerRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [data, loadingMore]);

  return (
    <div className="notification-list" ref={containerRef}>
      <List
        component="nav"
        sx={{
          '& .MuiListItemButton-root': {
            p: {
              xs: '5px',
              lg: 1.5
            },
            my: {
              xs: '5px',
              lg: 1.5
            },
            border: `1px solid ${theme.palette.divider}`,
            '&:hover': {
              bgcolor: '#414a551c',
              borderColor: theme.palette.primary.light,
              '& .arrow-button svg': {
                color: '#fff'
              }
            },
            '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
          }
        }}
      >
        {data.length > 0
          ? data.map((notification: any) => {
              const customer = notification?.customer;
              return (
                <ListItemButton key={notification.id} onClick={() => onClick(notification)}>
                  <ListItemAvatar sx={{ position: 'relative' }}>
                    <Avatar type="outlined">{renderNotificationIcon(notification?.type)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <div>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={4}>
                          <Typography
                            variant="h5"
                            color={theme.palette.primary[800]}
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              fontWeight: '800',
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: { xs: '10px', md: '16px' }
                            }}
                          >
                            {`${customer?.firstName} ${customer?.lastName}`} &nbsp;{' '}
                            {showStatus(customer?.riskLevel, false, {
                              height: '16px',
                              '& span': {
                                fontSize: '10px',
                                fontWeight: 400
                                // letterSpacing: '0.6px'
                              }
                            })}
                          </Typography>
                        </Stack>
                        <Stack spacing={0}>
                          <Typography
                            color="grey.400"
                            variant="caption"
                            sx={{
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              display: '-webkit-box',
                              textOverflow: 'ellipsis',
                              fontWeight: 600,
                              fontSize: { xs: '10px', md: '12px' }
                            }}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: notification?.text?.replace('{{username}}', `${customer?.firstName} ${customer?.lastName}`)
                              }}
                            ></span>
                          </Typography>

                          <Typography color="text.secondary" variant="caption" fontSize={'10px'}>
                            <TimeAgo date={notification?.createdAt} />
                          </Typography>
                        </Stack>
                      </div>
                    }
                  />
                  <ListItemAvatar
                    className="arrow-button"
                    sx={{
                      position: 'relative',
                      textAlign: 'right',
                      color: theme.palette.primary.light,
                      '& svg:hover': {
                        color: '#fff'
                      }
                    }}
                  >
                    <ArrowRight size={30} />
                  </ListItemAvatar>
                </ListItemButton>
              );
            })
          : null}
      </List>
    </div>
  );
};

export default NewNotificationTab;
