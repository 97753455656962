import { formatDateToUSFormat } from 'utils';

interface TimeAgoProps {
  date: string;
}

const TimeAgo = ({ date }: TimeAgoProps) => {
  const now = new Date();
  const newDate = new Date(date);
  const diffInMilliseconds = now.getTime() - newDate.getTime();
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
  const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
  if (diffInMinutes === 0) {
    return <span>{'Now'}</span>;
  } else if (diffInMinutes <= 59) {
    return <span>{`${diffInMinutes} mins ago`}</span>;
  } else if (diffInHours <= 23) {
    return <span>{`${diffInHours} hours ago`}</span>;
  } else if (diffInDays <= 7) {
    return <span>{`${diffInDays} days ago`}</span>;
  } else {
    // Format the date as needed, e.g., "September 7, 2023"
    const formattedDate = formatDateToUSFormat(date);
    return <span>{formattedDate}</span>;
  }
};

export default TimeAgo;
