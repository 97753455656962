import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

const EllipsisText = ({ text, maxCharacters, color }: any) => {
  // Check if the text length is greater than the maximum characters allowed
  const isOverflowing = text.length > maxCharacters;

  // If it's overflowing, truncate the text
  const truncatedText = isOverflowing ? text.slice(0, maxCharacters) + '...' : text;

  return (
    <Tooltip title={isOverflowing ? text : ''} arrow>
      <Typography
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
        color={color}
      >
        {truncatedText}
      </Typography>
    </Tooltip>
  );
};

export default EllipsisText;
