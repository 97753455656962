import { useMemo, useState } from 'react';

// material-ui
import {
  FormControl,
  MenuItem,
  OutlinedInput,
  OutlinedInputProps,
  Select,
  Slider,
  Stack,
  TextField,
  Tooltip,
  Chip,
  Autocomplete,
  Checkbox,
  Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTheme } from '@mui/material/styles';

// third-party
import { useAsyncDebounce, Row, TableState, MetaBase } from 'react-table';
import { matchSorter } from 'match-sorter';
import { format } from 'date-fns';

// project-imports
import IconButton from 'components/@extended/IconButton';

// assets
import { Add, Minus, SearchNormal1 } from 'iconsax-react';

// ==============================|| CUSTOM FUNCTION - REACT TABLE ||============================== //

interface GlobalFilterProps extends OutlinedInputProps {
  preGlobalFilteredRows: Row<{}>[];
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
}

export function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter, ...other }: GlobalFilterProps) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <OutlinedInput
      value={value || ''}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Search ${count} records...`}
      id="start-adornment-email"
      startAdornment={<SearchNormal1 size={18} />}
      {...other}
    />
  );
}

export function capitalizeFirstLowercaseRest(name: string) {
  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
}

export function DefaultColumnFilter({ column: { filterValue, Header, setFilter, id }, customFilterCallback, onSearch }: any) {
  return (
    <TextField
      fullWidth
      value={filterValue || ''}
      onChange={(e) => {
        if (customFilterCallback) {
          customFilterCallback({ value: e.target.value, id });
        }
        setFilter(e.target.value || undefined);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && onSearch) onSearch();
      }}
      placeholder={Header}
      size="small"
    />
  );
}

export function DateColumnFilter({ column: { filterValue, Header, setFilter, id }, customFilterCallback }: any) {
  return (
    <FormControl
      sx={{
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#3E4853!important'
        }
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          format="MM/dd/yyyy"
          value={filterValue ? new Date(filterValue) : ''}
          onChange={(newValue: any) => {
            let formatDateFn = '';
            try {
              if (newValue) formatDateFn = format(newValue, 'M/d/yyyy');
            } catch (error) {}
            if (customFilterCallback) {
              customFilterCallback({ value: formatDateFn, id });
            }
            setFilter(formatDateFn || undefined);
          }}
          slotProps={{ inputAdornment: { placeholder: `Select ${Header}` } }}
          sx={{
            '& .MuiInputBase-root': {
              minHeight: '37px',
              minWidth: '120px'
            },
            '& input': {
              padding: '8px',
              paddingRight: '0px',
              fontSize: '12px'
            },
            '& .MuiInputAdornment-root': {
              marginLeft: '0px'
            }
          }}
        />
      </LocalizationProvider>
      <Tooltip title="Reset">
        <IconButton
          size="small"
          color="error"
          onClick={() => {
            if (customFilterCallback) {
              customFilterCallback({ value: '', id });
            }
            setFilter(undefined);
          }}
        >
          <Add style={{ transform: 'rotate(45deg)' }} />
        </IconButton>
      </Tooltip>
    </FormControl>
  );
}

interface FilterColumnProps {
  filterValue?: never[];
  preFilteredRows: Row[];
  Header: string;
  setFilter: any;
  id: string;
  options?: any;
  dataType?: any;
}

export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, options, dataType },
  customFilterCallback
}: {
  column: FilterColumnProps;
  customFilterCallback: any;
}) {
  const defaultOptions = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row: Row) => {
      options.add({ value: row.values[id], label: row.values[id] });
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <Select
      sx={{ maxWidth: '200px' }}
      value={filterValue}
      onChange={(e) => {
        if (customFilterCallback) {
          customFilterCallback({ value: e.target.value, id });
        }
        setFilter(e.target.value || '');
      }}
      displayEmpty
      size="small"
    >
      <MenuItem value="">All</MenuItem>
      {[...(options ? options : defaultOptions)].map((option: any, i: number) => (
        <MenuItem key={i} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}

export function MultiSelectColumnFilter({ column: { setFilter, preFilteredRows, id, options, Header }, customFilterCallback }: any) {
  const [selectedOption, setSelectedOption] = useState([]);
  const defaultOptions = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row: any) => {
      options.add({ value: row.values[id], label: row.values[id] });
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  const handleChange = (_: any, data: any) => {
    let newSelection = data;

    // If any option other than "All" is selected, remove "All" from the selection
    if (data.some((d: any) => d.label !== 'All')) {
      newSelection = data.filter((d: any) => d.label !== 'All');
    }

    if (customFilterCallback) {
      customFilterCallback({ value: newSelection.map((d: any) => d.value), id, type: 'multiselect' });
    }

    setSelectedOption(newSelection);
    setFilter(newSelection);
  };

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={options ? options : defaultOptions ?? []}
      fullWidth
      value={selectedOption?.length > 0 ? selectedOption : [{ label: 'All', value: '' }]}
      defaultValue={selectedOption?.length > 0 ? selectedOption : [{ label: 'All', value: '' }]}
      disableCloseOnSelect
      getOptionLabel={(option: any) => option.label}
      renderOption={(props, option: any, { selected }) => (
        <li {...props}>
          <Checkbox style={{ marginRight: 8 }} checked={selected} />
          {option.label}
        </li>
      )}
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} placeholder={Header} />}
      size="small"
      sx={{
        minWidth: '140px',
        '& .MuiOutlinedInput-root': {
          p: 1,
          minWidth: '180px'
        },
        '& .MuiAutocomplete-tag': {
          bgcolor: 'primary.lighter',
          border: '1px solid',
          borderColor: 'primary.light',
          '& .MuiSvgIcon-root': {
            color: 'primary.main',
            '&:hover': {
              color: 'primary.dark'
            }
          }
        }
      }}
    />
  );
}

export function SliderColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }: { column: FilterColumnProps }) {
  const [min, max] = useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row: Row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <Stack direction="row" alignItems="center" spacing={1} sx={{ pl: 1, minWidth: 120 }}>
      <Slider
        value={filterValue || min}
        min={min}
        max={max}
        step={1}
        onChange={(event: Event, newValue: number | number[]) => {
          setFilter(newValue);
        }}
        valueLabelDisplay="auto"
        aria-labelledby="non-linear-slider"
      />
      <Tooltip title="Reset">
        <IconButton size="small" color="error" onClick={() => setFilter(undefined)}>
          <Add style={{ transform: 'rotate(45deg)' }} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}

export function TwoDimontialSliderColumnFilter({
  column: { filterValue, setFilter, id },
  customFilterCallback
}: {
  column: FilterColumnProps;
  customFilterCallback: any;
}) {
  return (
    <Stack direction="row" alignItems="center" spacing={1} sx={{ pl: 1, minWidth: 120 }}>
      <Slider
        value={filterValue || [0, 100]}
        step={1}
        onChange={(event: Event, newValue: number | number[]) => {
          if (customFilterCallback) {
            customFilterCallback({ value: newValue, id });
          }
          setFilter(newValue);
        }}
        valueLabelDisplay="auto"
        aria-labelledby="non-linear-slider"
      />
      <Tooltip title="Reset">
        <IconButton
          size="small"
          color="error"
          onClick={() => {
            if (customFilterCallback) {
              customFilterCallback({ value: [0, 100], id });
            }
            setFilter(undefined);
          }}
        >
          <Add style={{ transform: 'rotate(45deg)' }} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}

export function NumberRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }: { column: FilterColumnProps }) {
  const [min, max] = useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row: Row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <Stack direction="row" alignItems="center" spacing={1} sx={{ minWidth: 168, maxWidth: 250 }}>
      <TextField
        fullWidth
        value={filterValue[0] || ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
        }}
        placeholder={`Min (${min})`}
        size="small"
      />
      <Minus />
      <TextField
        fullWidth
        value={filterValue[1] || ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
        }}
        placeholder={`Max (${max})`}
        size="small"
      />
    </Stack>
  );
}

function fuzzyTextFilterFn(rows: Row[], id: number, filterValue: string) {
  return matchSorter(rows, filterValue, { keys: [(row: Row) => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = (val: number) => !val;

export const renderFilterTypes: any = () => ({
  fuzzyText: fuzzyTextFilterFn,
  text: (rows: Row[], id: string, filterValue: string) => {
    rows.filter((row: Row) => {
      const rowValue = row.values[id];
      return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true;
    });
  }
});

export function filterGreaterThan(rows: Row[], id: number, filterValue: number) {
  return rows.filter((row: Row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

filterGreaterThan.autoRemove = (val: number) => typeof val !== 'number';

export function useControlledState(state: TableState<{}>, { instance }: MetaBase<{}>) {
  return useMemo(() => {
    if (state.groupBy.length) {
      return {
        ...state,
        hiddenColumns: [...state.hiddenColumns!, ...state.groupBy].filter((d, i, all) => all.indexOf(d) === i)
      };
    }
    return state;
  }, [state]);
}

export const outcomeCensusColor: { [key: string]: string } = {
  'Very Low Risk': 'veryLow',
  'Low Risk': 'low',
  'Moderate Risk': 'moderate',
  'High Risk': 'high',
  'Very High Risk': 'veryHigh',
  Undetermined: 'undetermined',
  Unresponsive: 'unresponsive',
  Imminent: 'imminent',
  'NSI-1': 'NSI-1',
  'PSI-2': 'PSI-2',
  'PSI-3': 'PSI-3',
  'ASI-4': 'ASI-4',
  'ASI-5': 'ASI-5'
};

export function showStatus(value: string, isStyle?: boolean, style: any = {}) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const theme = useTheme() as any;
  switch (value) {
    case 'Very High Risk':
      return (
        <Chip
          label="Very high"
          size="small"
          variant="light"
          sx={{
            color: '#fff',
            background: theme.palette.info.veryHigh,
            position: isStyle ? 'absolute' : '',
            right: isStyle ? 10 : '',
            top: isStyle ? 10 : '',
            ...style
          }}
        />
      );
    case 'High Risk':
      return (
        <Chip
          label="High"
          size="small"
          variant="light"
          sx={{
            color: '#fff',
            background: theme.palette.info.high,
            position: isStyle ? 'absolute' : '',
            right: isStyle ? 10 : '',
            top: isStyle ? 10 : '',
            ...style
          }}
        />
      );
    case 'Very Low Risk':
      return (
        <Chip
          label="Very low"
          size="small"
          variant="light"
          sx={{
            color: '#fff',
            background: theme.palette.info.veryLow,
            position: isStyle ? 'absolute' : '',
            right: isStyle ? 10 : '',
            top: isStyle ? 10 : '',
            ...style
          }}
        />
      );
    case 'Low Risk':
      return (
        <Chip
          label="Low"
          size="small"
          variant="light"
          sx={{
            color: '#fff',
            background: theme.palette.info.low,
            position: isStyle ? 'absolute' : '',
            right: isStyle ? 10 : '',
            top: isStyle ? 10 : '',
            ...style
          }}
        />
      );
    case 'NSI-1':
      return (
        <Chip
          label="General Discussion (NSI-1)"
          size="small"
          variant="light"
          sx={{
            color: '#fff',
            background: theme.palette.info.veryLow,
            position: isStyle ? 'absolute' : '',
            right: isStyle ? 10 : '',
            top: isStyle ? 10 : '',
            ...style
          }}
        />
      );
    case 'PSI-2':
      return (
        <Chip
          label="Low Emotional Concern (PSI-2)"
          size="small"
          variant="light"
          sx={{
            color: '#fff',
            background: theme.palette.info.low,
            position: isStyle ? 'absolute' : '',
            right: isStyle ? 10 : '',
            top: isStyle ? 10 : '',
            ...style
          }}
        />
      );
    case 'PSI-3':
      return (
        <Chip
          color="warning"
          label="Moderate Distress Concern (PSI-3)"
          size="small"
          variant="light"
          sx={{
            position: isStyle ? 'absolute' : '',
            right: isStyle ? 10 : '',
            top: isStyle ? 10 : '',
            ...style
          }}
        />
      );
    case 'ASI-4':
      return (
        <Chip
          label="High Suicide Risk (ASI-4)"
          size="small"
          variant="light"
          sx={{
            color: '#fff',
            background: theme.palette.info.high,
            position: isStyle ? 'absolute' : '',
            right: isStyle ? 10 : '',
            top: isStyle ? 10 : '',
            ...style
          }}
        />
      );
    case 'ASI-5':
      return (
        <Chip
          label="Imminent Suicide Risk (ASI-5)"
          size="small"
          variant="light"
          sx={{
            color: '#fff',
            background: theme.palette.info.veryHigh,
            position: isStyle ? 'absolute' : '',
            right: isStyle ? 10 : '',
            top: isStyle ? 10 : '',
            ...style
          }}
        />
      );
    case 'Unresponsive':
      return (
        <Chip
          label="Unresponsive"
          size="small"
          variant="filled"
          className="filledSecondary"
          sx={{
            color: '#fff',
            background: theme.palette.info.unresponsive,
            position: isStyle ? 'absolute' : '',
            right: isStyle ? 10 : '',
            top: isStyle ? 10 : '',
            ...style
          }}
        />
      );
    case 'Undetermined':
      return (
        <Chip
          label="Undetermined"
          size="small"
          variant="outlined"
          sx={{
            borderColor: theme.palette.info.undetermined,
            color: theme.palette.info.undetermined,
            position: isStyle ? 'absolute' : '',
            right: isStyle ? 10 : '',
            top: isStyle ? 10 : '',
            ...style
          }}
        />
      );
    case 'Imminent':
      return (
        <Chip
          label="Imminent"
          size="small"
          variant="filled"
          className="filledSecondary"
          sx={{
            color: '#fff',
            background: theme.palette.info.imminent,
            position: isStyle ? 'absolute' : '',
            right: isStyle ? 10 : '',
            top: isStyle ? 10 : '',
            ...style
          }}
        />
      );
    case 'Moderate':
      return (
        <Chip
          color="warning"
          label="Moderate"
          size="small"
          variant="light"
          sx={{
            position: isStyle ? 'absolute' : '',
            right: isStyle ? 10 : '',
            top: isStyle ? 10 : '',
            ...style
          }}
        />
      );
    default:
      return <Typography>-</Typography>;
  }
}
export function roundedMedian(leafValues: any) {
  let min = leafValues[0] || 0;
  let max = leafValues[0] || 0;

  leafValues.forEach((value: number) => {
    min = Math.min(min, value);
    max = Math.max(max, value);
  });

  return Math.round((min + max) / 2);
}
