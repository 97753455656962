// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { People } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  organization: People
};

// ==============================|| MENU ITEMS - HOME ||============================== //

const profile: NavItemType = {
  id: 'profiles',
  type: 'group',
  allowedRoles: ['super-admin', 'admin'],
  children: [
    {
      id: 'OrganizationSettings',
      title: <FormattedMessage id="Organization Settings" />,
      type: 'collapse',
      icon: icons.organization,
      visible: false,
      children: [
        {
          id: 'Users',
          title: <FormattedMessage id="Users" />,
          type: 'item',
          url: '/profiles/organization/users'
        },
        {
          id: 'locations',
          title: <FormattedMessage id="Locations" />,
          type: 'item',
          url: '/profiles/organization/locations'
        },
        {
          id: 'Webchat',
          title: <FormattedMessage id="Webchat" />,
          type: 'item',
          url: '/profiles/organization/webchat'
        },
        {
          id: 'billing',
          title: <FormattedMessage id="Billing" />,
          type: 'item',
          url: '/profiles/organization/billing'
        }
      ]
    },

    {
      id: 'ProfileSettings',
      title: <FormattedMessage id="Profile" />,
      type: 'collapse',
      icon: icons.organization,
      visible: false,
      children: [
        {
          id: 'Settings',
          title: <FormattedMessage id="Notification settings" />,
          type: 'item',
          url: '/profiles/settings/notification'
        },
        {
          id: 'account',
          title: <FormattedMessage id="Account" />,
          type: 'item',
          url: '/profiles/settings/account'
        },
        {
          id: 'change-password',
          title: <FormattedMessage id="Change password" />,
          type: 'item',
          url: '/profiles/settings/change-password'
        }
      ]
    }
  ]
};

export default profile;
