// ChatContext.tsx
import { CONVERSTATION_ITEMS_LIMIT } from 'data/constant';
import { ReactElement, createContext, useContext, useState } from 'react';
import { dispatch, useSelector } from 'store';
import { getConversations } from 'store/reducers/chat';
import { Conversation } from 'types/chat';

// Define the context type
type ChatContextType = {
  conversationParams: string;
  selectedFacilities: string;
  setSelectedFacilities: React.Dispatch<React.SetStateAction<string>>;
  setConversationParams: React.Dispatch<React.SetStateAction<string>>;
  selectedGroup: string;
  setSelectedGroup: React.Dispatch<React.SetStateAction<string>>;
  isOpenMediaPreview: boolean;
  setIsOpenMediaPreview: React.Dispatch<React.SetStateAction<boolean>>;
  mediaObject: any;
  setMediaObject: React.Dispatch<React.SetStateAction<any>>;
  conversationLimit: number;
  conversationOffset: number;
  setConversationLimit: React.Dispatch<React.SetStateAction<number>>;
  setConversationOffset: React.Dispatch<React.SetStateAction<number>>;
  conversationsList: Conversation[];
  setConversationsList: React.Dispatch<React.SetStateAction<Conversation[]>>;
  handleSearchConversation: (search: string, conversationId?: string) => void;
  setScheduledMessageData?: any;
  scheduledMessageData?: any;
};

const ContextCallBack = async () => {
  // throw new Error('ChatContext not initialized');
};
// Create the initial context with default values
const ChatContext = createContext<ChatContextType>({
  setSelectedFacilities: ContextCallBack,
  setConversationParams: ContextCallBack,
  selectedFacilities: '',
  conversationParams: '',
  setSelectedGroup: ContextCallBack,
  selectedGroup: '',
  setIsOpenMediaPreview: ContextCallBack,
  isOpenMediaPreview: false,
  setMediaObject: ContextCallBack,
  mediaObject: null,
  conversationLimit: 5,
  conversationOffset: 0,
  setConversationLimit: ContextCallBack,
  setConversationOffset: ContextCallBack,
  conversationsList: [],
  setConversationsList: ContextCallBack,
  handleSearchConversation: ContextCallBack
});

// Create a provider component to wrap your application with the context
export const ChatProvider = ({ children }: { children: ReactElement }) => {
  const [conversationParams, setConversationParams] = useState<string>('new_to_old');
  const [selectedFacilities, setSelectedFacilities] = useState<any>('All');
  const [selectedGroup, setSelectedGroup] = useState<string>('all');
  const [isOpenMediaPreview, setIsOpenMediaPreview] = useState<boolean>(false);
  const [mediaObject, setMediaObject] = useState<any>({});
  const [conversationLimit, setConversationLimit] = useState<any>(CONVERSTATION_ITEMS_LIMIT);
  const [conversationOffset, setConversationOffset] = useState<any>(0);
  const [conversationsList, setConversationsList] = useState<Conversation[]>([]);
  const [scheduledMessageData, setScheduledMessageData] = useState<any>(null);
  const { selectedLocation } = useSelector((state) => state.organization);

  const handleSearchConversation = (search: string, conversationId = '') => {
    if (!selectedLocation) {
      return;
    }
    setConversationLimit(CONVERSTATION_ITEMS_LIMIT);
    setConversationOffset(0);
    setConversationsList([]);
    dispatch(
      getConversations({
        locationId: selectedLocation ?? '',
        isLoader: true,
        facilities: selectedFacilities,
        filter: conversationParams,
        search,
        selectedGroup,
        conversationId
      })
    );
  };

  return (
    <ChatContext.Provider
      value={{
        conversationParams,
        setConversationParams,
        setSelectedFacilities,
        selectedFacilities,
        isOpenMediaPreview,
        setIsOpenMediaPreview,
        mediaObject,
        setMediaObject,
        conversationLimit,
        conversationOffset,
        setConversationLimit,
        setConversationOffset,
        conversationsList,
        setConversationsList,
        handleSearchConversation,
        selectedGroup,
        setSelectedGroup,
        setScheduledMessageData,
        scheduledMessageData
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

// Custom hook to access the API context
export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChat must be used within an ChatProvider');
  }
  return context;
};
