// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { People } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  organization: People
};

// ==============================|| MENU ITEMS - HOME ||============================== //

const home: NavItemType = {
  id: 'super-admin',
  title: <FormattedMessage id="Super admin" />,
  type: 'group',
  allowedRoles: ['super-admin'],
  children: [
    {
      id: 'organization',
      title: <FormattedMessage id="Organization" />,
      type: 'collapse',
      icon: icons.organization,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="List" />,
          type: 'item',
          url: '/organization/list'
        },
        {
          id: 'onboard',
          title: <FormattedMessage id="Onboarding" />,
          type: 'item',
          url: '/organization/onboard'
        }
      ]
    }
  ]
};

export default home;
