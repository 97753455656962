// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// types
import { WorkflowStateProps } from 'types/workflow';
import { enqueueSnackbar } from 'notistack';
import { errorSnackConfig } from '../../config';

const initialState: WorkflowStateProps = {
  error: null,
  loading: true,
  workflows: [],
  outComeWorkflows: [],
  workflowTableData: [],
  workflowMetrics: {},
  trendStories: [],
  outcomeCensus: null,
  workflowResponseDataLoading: false,
  workflowResponseData: [],
  workflowResponseCount: 0,

  censusOutComeCustomerLoading: false,
  censusOutComeCustomer: [],
  censusOutComeCustomerCount: 0,
  selectedWorkflowId: null,
  workflowMetricsByLocation: [],
  workflowOutcomeReportSummary: {},
  outcomeDataReport: {},
  surveyCompletionStats: {},
  engagementDroprateStats: {},
  surveyCompletionByIntervalStats: {}
};

// ==============================|| SLICE - CHAT ||============================== //

const workflow = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    getWorkflow(state) {
      state.loading = true;
      state.workflows = [];
    },
    getWorkflowSuccess(state, action) {
      state.loading = false;
      state.workflows = action.payload;
    },
    getWorkflowTableData(state) {
      state.workflowTableData = [];
    },
    getWorkflowTableDataSuccess(state, action) {
      state.workflowTableData = action.payload;
    },
    getWorkflowMetricsSuccess(state, action) {
      state.workflowMetrics = action.payload;
    },
    getWorkflowTrendStorySuccess(state, action) {
      state.trendStories = action.payload ?? [];
    },
    getWorkflowOutcomeCensusSuccess(state, action) {
      state.outcomeCensus = action.payload ?? {};
    },
    getOutComeWorkflowSuccess(state, action) {
      state.outComeWorkflows = action.payload;
    },
    getCustomersByWorkflowResponse(state) {
      state.workflowResponseDataLoading = true;
    },
    getCustomersByWorkflowResponseSuccess(state, action) {
      state.workflowResponseData = action.payload?.data ?? [];
      state.workflowResponseCount = action.payload?.count;
      state.workflowResponseDataLoading = false;
    },
    getCensusOutComeCustomer(state) {
      state.censusOutComeCustomerLoading = true;
    },
    getCensusOutComeCustomerSuccess(state, action) {
      state.censusOutComeCustomer = action.payload?.data ?? [];
      state.censusOutComeCustomerCount = action.payload?.count;
      state.censusOutComeCustomerLoading = false;
    },
    setSelectedWorkflowId(state, action) {
      state.selectedWorkflowId = action.payload;
    },
    resetWorkflowStats(state) {
      state.workflows = [];
      state.trendStories = [];
      state.outcomeCensus = null;
      state.outComeWorkflows = [];
    },
    getWorkflowsMetricsByLocation(state) {
      state.loading = true;
      state.workflowMetricsByLocation = [];
    },
    getWorkflowsMetricsByLocationSuccess(state, action) {
      state.loading = false;
      state.workflowMetricsByLocation = action.payload;
    },
    getReportSummary(state) {
      state.loading = true;
      state.workflowOutcomeReportSummary = {};
    },
    getReportSummarySuccess(state, action) {
      state.loading = false;
      state.workflowOutcomeReportSummary = action.payload;
    },
    getOutcomeDataReport(state) {
      state.loading = true;
      state.outcomeDataReport = {};
    },
    getOutcomeDataReportSuccess(state, action) {
      state.loading = false;
      state.outcomeDataReport = action.payload;
    },
    getSurveyCompletionStats(state) {
      state.loading = true;
      state.surveyCompletionStats = {};
    },
    getSurveyCompletionStatsSuccess(state, action) {
      state.loading = true;
      state.surveyCompletionStats = action.payload;
    },
    getEngagementAndDropRate(state) {
      state.loading = true;
      state.engagementDroprateStats = {};
    },
    getEngagementAndDropRateSuccess(state, action) {
      state.loading = true;
      state.engagementDroprateStats = action.payload;
    },
    getCompletionStatsByInterval(state) {
      state.loading = true;
      state.surveyCompletionByIntervalStats = {};
    },
    getCompletionStatsByIntervalSuccess(state, action) {
      state.loading = true;
      state.surveyCompletionByIntervalStats = action.payload;
    }
  }
});

// Reducer
export default workflow.reducer;

export const { getWorkflowOutcomeCensusSuccess, getWorkflowTrendStorySuccess, resetWorkflowStats } = workflow.actions;

export function getWorkflows(locationId: string) {
  return async () => {
    try {
      dispatch(workflow.actions.getWorkflow());
      const response = await axios.get(`/workflows?locationId=${locationId}`);
      dispatch(workflow.actions.getWorkflowSuccess(response.data.payload?.workflows || []));
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to fetch workflows.', errorSnackConfig as any);
      dispatch(workflow.actions.hasError(error));
    }
  };
}

export function getOutComeWorkflowSuccess(locationId: string, isOutcomeSurvey: boolean = true) {
  return async () => {
    try {
      const response = await axios.get(`/workflows?locationId=${locationId}&isOutcomeSurvey=${isOutcomeSurvey}`);
      dispatch(workflow.actions.getOutComeWorkflowSuccess(response.data.payload?.workflows || []));
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to fetch workflows.', errorSnackConfig as any);
      dispatch(workflow.actions.hasError(error));
    }
  };
}

export function getWorkflowTableData(workflowId: string) {
  return async () => {
    try {
      dispatch(workflow.actions.getWorkflowTableData());
      const response = await axios.get(`/workflows/${workflowId}/getWorkflowTableData`);
      dispatch(workflow.actions.getWorkflowTableDataSuccess(response.data.payload || []));
    } catch (error: any) {
      dispatch(workflow.actions.hasError(error));
      enqueueSnackbar(error.error?.message ?? 'Failed to load workflow data.', errorSnackConfig as any);
    }
  };
}

export function getOutcomeSurveyOverviewData(workflowId: string, startDate: string, endDate: string) {
  return async () => {
    try {
      const response = await axios.get(`/workflows/${workflowId}/getOutcomeSurveyOverviewData?startDate=${startDate}&endDate=${endDate}`);
      return response.data;
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to load outcome survey overview data.', errorSnackConfig as any);
    }
  };
}

export function getAlumniOutcomeOverviewData(workflowId: string, startDate: string, endDate: string, params: any) {
  return async () => {
    try {
      const response = await axios.get(
        `/workflows/${workflowId}/getAlumniOutcomeOverviewData?startDate=${startDate}&endDate=${endDate}&riskLevel=${
          params.riskLevel ? params.riskLevel?.filter((r: any) => r) : ''
        }&clientName=${params.clientName ?? ''}&dischargeDate=${params.dischargeDate ?? ''}&admissionDate=${params.admissionDate ?? ''}`
      );
      return response.data;
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to load alumni outcome overview data.', errorSnackConfig as any);
    }
  };
}

export function getWorkflowMetrics(workflowId: string) {
  return async () => {
    try {
      const response = await axios.get(`/workflows/${workflowId}/getWorkflowMetrics`);
      dispatch(workflow.actions.getWorkflowMetricsSuccess(response.data.payload || []));
    } catch (error: any) {
      dispatch(workflow.actions.hasError(error));
      enqueueSnackbar(error.error?.message ?? 'Failed to load workflow metrics.', errorSnackConfig as any);
    }
  };
}

export function getWorkflowTrendStory(workflowId: string, params = 'all') {
  return async () => {
    try {
      const response = await axios.get(`/workflows/${workflowId}/getWorkflowTrendStory?dateRangeFilter=${params}`);
      dispatch(workflow.actions.getWorkflowTrendStorySuccess(response.data.payload));
    } catch (error: any) {
      dispatch(workflow.actions.hasError(error));
      enqueueSnackbar(error.error?.message ?? 'Failed to load workflow TrendStory.', errorSnackConfig as any);
    }
  };
}

export function getWorkflowOutcomeCensus(workflowId: string) {
  return async () => {
    try {
      dispatch(workflow.actions.setSelectedWorkflowId(workflowId));
      const response = await axios.get(`/workflows/${workflowId}/getWorkflowOutcomeCensus`);
      dispatch(workflow.actions.getWorkflowOutcomeCensusSuccess(response.data.payload));
    } catch (error: any) {
      dispatch(workflow.actions.hasError(error));
      enqueueSnackbar(error.error?.message ?? 'Failed to load workflow TrendStory.', errorSnackConfig as any);
    }
  };
}

export function getWorkflowOutcomeCensusCustomers(workflowId: string, params: any) {
  return async () => {
    try {
      dispatch(workflow.actions.getCensusOutComeCustomer());
      const response = await axios.get(
        `/workflows/${workflowId}/getWorkflowOutcomeCensusCustomers?workflowId=${workflowId}&riskLevel=${params.riskLevel}&limit=${
          params.limit
        }&offset=${params.offset}&clientName=${params.clientName ?? ''}&dischargeDate=${params.dischargeDate ?? ''}`
      );
      dispatch(workflow.actions.getCensusOutComeCustomerSuccess(response.data.payload));
    } catch (error: any) {
      dispatch(workflow.actions.hasError(error));
      enqueueSnackbar(error.error?.message ?? 'Failed to load workflow TrendStory.', errorSnackConfig as any);
    }
  };
}

export function getCustomersByWorkflowResponse(workflowId: string, params: any) {
  return async () => {
    try {
      dispatch(workflow.actions.getCustomersByWorkflowResponse());
      //TODO: change, and make a utility function to handle this
      const response = await axios.get(
        `/workflows/${workflowId}/getCustomersByWorkflowResponse` +
          `?actionId=${params.actionId}` +
          (params.questionId ? `&questionId=${params.questionId}` : '') +
          `&limit=${params.limit}` +
          `&offset=${params.offset}` +
          `&response=${params.response}` +
          `&clientName=${params.clientName ?? ''}` +
          `&dischargeDate=${params.dischargeDate ?? ''}` +
          `&startDate=${params.startDate ?? ''}` +
          `&endDate=${params.endDate ?? ''}`
      );
      dispatch(workflow.actions.getCustomersByWorkflowResponseSuccess(response.data.payload));
    } catch (error: any) {
      dispatch(workflow.actions.hasError(error));
      enqueueSnackbar(error.error?.message ?? 'Failed to load workflow TrendStory.', errorSnackConfig as any);
    }
  };
}

export function sendCustomersByWorkflowResponseDataInMail(workflowId: string, params: any) {
  return async () => {
    try {
      const response = await axios.post(`/workflows/${workflowId}/sendCustomersByWorkflowResponseDataInMail`, params);
      return response;
    } catch (error: any) {
      dispatch(workflow.actions.hasError(error));
      enqueueSnackbar(error.error?.message ?? 'Failed to export response data.', errorSnackConfig as any);
    }
  };
}

export function updateCustomerResponseManually(workflowId: string, values: any) {
  return async () => {
    try {
      const response = await axios.post(`/workflows/${workflowId}/updateCustomerResponseManually`, values);
      return response;
    } catch (error: any) {
      dispatch(workflow.actions.hasError(error));
      enqueueSnackbar(error.error?.message ?? 'Failed to update.', errorSnackConfig as any);
    }
  };
}

export function getWorkflowsMetricsByLocation(locationId: string) {
  return async () => {
    try {
      dispatch(workflow.actions.getWorkflowsMetricsByLocation());
      const response = await axios.get(`/locations/${locationId}/getWorkflowsMetricsByLocation`);
      dispatch(workflow.actions.getWorkflowsMetricsByLocationSuccess(response.data.payload || []));
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to fetch workflows.', errorSnackConfig as any);
      dispatch(workflow.actions.hasError(error));
    }
  };
}

export function getReportSummaryData(workflowId: string) {
  return async () => {
    try {
      dispatch(workflow.actions.getReportSummary());
      const response = await axios.get(`/workflows/${workflowId}/getReportSummary`);
      dispatch(workflow.actions.getReportSummarySuccess(response.data.payload || []));
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to fetch workflows.', errorSnackConfig as any);
      dispatch(workflow.actions.hasError(error));
    }
  };
}

export function getOutcomeReportData(locationId: string) {
  return async () => {
    try {
      dispatch(workflow.actions.getOutcomeDataReport());
      const response = await axios.get(`/locations/${locationId}/getOutcomeDataReport`);
      dispatch(workflow.actions.getOutcomeDataReportSuccess(response.data.payload || []));
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to fetch workflows.', errorSnackConfig as any);
      dispatch(workflow.actions.hasError(error));
    }
  };
}

export function getSurveyCompletionStatsData(workflowId: string, params = 'all') {
  return async () => {
    try {
      dispatch(workflow.actions.getSurveyCompletionStats());
      const response = await axios.get(`/workflows/${workflowId}/getOutcomeSurveyCompletionStats?dateRangeFilter=${params}`);
      dispatch(workflow.actions.getSurveyCompletionStatsSuccess(response.data.payload || []));
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to fetch workflows.', errorSnackConfig as any);
      dispatch(workflow.actions.hasError(error));
    }
  };
}

export function getEngagementAndDropRateStats(workflowId: string) {
  return async () => {
    try {
      dispatch(workflow.actions.getEngagementAndDropRate());
      const response = await axios.get(`/workflows/${workflowId}/getEngagementAndDropRateStats`);
      dispatch(workflow.actions.getEngagementAndDropRateSuccess(response.data.payload || []));
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to fetch workflows.', errorSnackConfig as any);
      dispatch(workflow.actions.hasError(error));
    }
  };
}

export function getOutcomeSurveyCompletionByInterval(workflowId: string) {
  return async () => {
    try {
      dispatch(workflow.actions.getCompletionStatsByInterval());
      const response = await axios.get(`/workflows/${workflowId}/getOutcomeSurveyCompletionByInterval`);
      dispatch(workflow.actions.getCompletionStatsByIntervalSuccess(response.data.payload || []));
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to fetch workflows.', errorSnackConfig as any);
      dispatch(workflow.actions.hasError(error));
    }
  };
}
