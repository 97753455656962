import { useState, MouseEvent } from 'react';

// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import { Logout, Setting, UserSquare, PasswordCheck } from 'iconsax-react';
import { useNavigate } from 'react-router';
// import useAuth from 'hooks/useAuth';
// import { RolePagesAccess } from 'data/constant';
// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

interface Props {
  handleLogout: () => void;
  handleToggle: any;
}

const ProfileTab = ({ handleLogout, handleToggle }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  // const { user } = useAuth();
  const navigate = useNavigate();
  const handleListItemClick = (event: MouseEvent<HTMLDivElement>, index: number, link: string | null = null) => {
    handleToggle();
    setSelectedIndex(index);
    if (link) {
      navigate(link);
    }
  };
  // const isRoleAccess = (arr: any): boolean => arr.some((item: string[]) => user?.roles?.includes(item));

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      {/* <ListItemButton selected={selectedIndex === 0} onClick={(event: MouseEvent<HTMLDivElement>) => handleListItemClick(event, 0)}>
        <ListItemIcon>
          <Edit2 variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary="Edit Profile" />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 1} onClick={(event: MouseEvent<HTMLDivElement>) => handleListItemClick(event, 1)}>
        <ListItemIcon>
          <Profile variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary="View Profile" />
      </ListItemButton>

      <ListItemButton selected={selectedIndex === 3} onClick={(event: MouseEvent<HTMLDivElement>) => handleListItemClick(event, 3)}>
        <ListItemIcon>
          <Profile2User variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary="Social Profile" />
      </ListItemButton> */}
      {/* {isRoleAccess(RolePagesAccess.profiles_organization) && (
        <ListItemButton
          selected={selectedIndex === 4}
          onClick={(event: MouseEvent<HTMLDivElement>) => handleListItemClick(event, 4, '/profiles/organization/billing')}
        >
          <ListItemIcon>
            <Card variant="Bulk" size={18} />
          </ListItemIcon>
          <ListItemText primary="Billing" />
        </ListItemButton>
      )} */}
      <ListItemButton
        selected={selectedIndex === 6}
        onClick={(event: MouseEvent<HTMLDivElement>) => handleListItemClick(event, 6, '/profiles/settings/account')}
      >
        <ListItemIcon>
          <UserSquare variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary="Account" />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 7}
        onClick={(event: MouseEvent<HTMLDivElement>) => handleListItemClick(event, 7, '/profiles/settings/change-password')}
      >
        <ListItemIcon>
          <PasswordCheck variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary="Change password" />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 5}
        onClick={(event: MouseEvent<HTMLDivElement>) => handleListItemClick(event, 5, '/profiles/settings/notification')}
      >
        <ListItemIcon>
          <Setting variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary="Notification settings" />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
        <ListItemIcon>
          <Logout variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};

export default ProfileTab;
