// third-party
import { combineReducers } from 'redux';

// project-imports
import menu from './menu';
import snackbar from './snackbar';
import chat from './chat';
import organization from './organization';
import common from './common';
import workflow from './workflows';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  chat,
  organization,
  common,
  workflow
});

export default reducers;
