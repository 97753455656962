// project-imports
import home from './home';
import profile from './profile';
import superAdmin from './super-admin';

// types
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [home, profile, superAdmin]
};

export default menuItems;
