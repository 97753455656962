// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Home2, Messages2, Map1, Send2, Chart21 } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  dashboard: Home2,
  chat: Messages2,
  workflows: Map1,
  sendWorkflow: Send2,
  reports: Chart21
};

// ==============================|| MENU ITEMS - HOME ||============================== //

const home: NavItemType = {
  id: 'home',
  title: <FormattedMessage id="home" />,
  type: 'group',
  allowedRoles: ['user', 'super-admin', 'admin'],
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="Dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: icons.dashboard
    },
    {
      id: 'chat',
      title: <FormattedMessage id="Chat" />,
      type: 'item',
      url: '/chat',
      icon: icons.chat,
      breadcrumbs: false
    },
    {
      id: 'reports/analytics',
      title: <FormattedMessage id="Reports/Analytics" />,
      type: 'collapse',
      icon: icons.reports,
      breadcrumbs: true,
      children: [
        // {
        //   id: 'calls/messages',
        //   title: <FormattedMessage id="Calls and messages" />,
        //   type: 'item',
        //   url: '/'
        // },
        // {
        //   id: 'client data',
        //   title: <FormattedMessage id="Client data" />,
        //   type: 'item',
        //   url: '/'
        // },
        // {
        //   id: 'organization overview',
        //   title: <FormattedMessage id="Organization overview" />,
        //   type: 'item',
        //   url: '/'
        // },
        {
          id: 'outcomes data',
          title: <FormattedMessage id="Outcomes" />,
          type: 'item',
          url: '/reports/outcomes'
        }
      ]
    },
    {
      id: 'send workflows',
      title: <FormattedMessage id="Send workflows" />,
      type: 'item',
      url: '/workflows/send',
      icon: icons.sendWorkflow,
      breadcrumbs: false
    },
    {
      id: 'workflows',
      title: <FormattedMessage id="Workflows" />,
      type: 'collapse',
      icon: icons.workflows,
      children: [
        {
          id: 'loader',
          disabled: true,
          title: <FormattedMessage id="Loading..." />,
          type: 'item',
          url: `/404`
        }
      ]
    }
  ]
};

export default home;
