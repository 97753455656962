// material-ui
import { alpha, styled } from '@mui/material/styles';
import { Theme, useMediaQuery, useTheme } from '@mui/material';

// third-party
import { Props } from 'simplebar-react';
// import { BrowserView } from 'react-device-detect';
import { MUIStyledCommonProps } from '@mui/system';
import { ReactNode, useCallback, useRef } from 'react';
import { dispatch, useSelector } from 'store';
import { getConversations } from 'store/reducers/chat';
import { useChatContext } from 'contexts/ChatContext';

// root style
// const RootStyle = styled(BrowserView)({
//   flexGrow: 1,
//   height: '100%',
//   overflow: 'hidden'
// });

// scroll bar wrapper
const SimpleBarStyle = styled('div')(({ theme }) => ({
  maxHeight: '100%',
  '& .simplebar-scrollbar': {
    '&:before': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.25)
    },
    '&.simplebar-visible:before': {
      opacity: 1
    }
  },
  '& .simplebar-track.simplebar-vertical': {
    width: 10
  },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: 6
  },
  '& .simplebar-mask': {
    zIndex: 'inherit'
  }
}));

// ==============================|| SIMPLE SCROLL BAR  ||============================== //

export default function SimpleBarScroll({ children, sx, ...other }: MUIStyledCommonProps<Theme> & Props) {
  const theme = useTheme();
  const smDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const { selectedLocation } = useSelector((state) => state.organization);
  const { conversationLoading, conversationCount } = useSelector((state) => state.chat);

  // const [scrollPosition, setScrollPosition] = useState(0);
  const scrollableNodeRef = useRef(null);
  const conversationTimeout = useRef<any>();
  const { conversationParams, selectedGroup, conversationLimit, conversationOffset, setConversationOffset } = useChatContext();

  const handleScroll = useCallback(() => {
    const scrollableNode = scrollableNodeRef.current;
    if (scrollableNode && !conversationLoading && conversationOffset < conversationCount) {
      const { scrollTop, clientHeight, scrollHeight } = scrollableNode;
      if (smDevice || scrollTop + clientHeight >= scrollHeight - 2) {
        if (conversationTimeout.current) {
          clearTimeout(conversationTimeout.current);
        }
        conversationTimeout.current = setTimeout(() => {
          dispatch(
            getConversations({
              locationId: selectedLocation ?? '',
              isLoader: true,
              filter: conversationParams,
              conversationLimit,
              conversationOffset: conversationOffset + conversationLimit,
              selectedGroup
            })
          );
          setConversationOffset(conversationOffset + conversationLimit);
        }, 500);
      }

      // Update the scroll position state
      // setScrollPosition(scrollTop);
    }
  }, [
    conversationLoading,
    conversationOffset,
    conversationCount,
    selectedLocation,
    conversationParams,
    conversationLimit,
    selectedGroup,
    setConversationOffset,
    smDevice
  ]);

  return (
    <>
      {/* {!smDevice ? ( */}
      {/* <RootStyle> */}
      <SimpleBarStyle clickOnTrack={false} sx={sx} {...other} ref={scrollableNodeRef} onScroll={handleScroll}>
        {children as ReactNode}
      </SimpleBarStyle>
      {/* </RootStyle> */}
    </>
  );
}
