export const CONVERSTATION_ITEMS_LIMIT = 10;

// red to green
export const COLOR_ORDER = ['#C33B3B', '#E76A6A', '#E88447', '#329F6B', '#127463'];

export const RolePagesAccess: { [key: string]: string[] } = {
  dashboard: ['user', 'super-admin', 'admin'],
  chat: ['user', 'super-admin', 'admin'],
  organization: ['super-admin'],
  profiles: ['user', 'super-admin', 'admin'],
  profiles_organization: ['super-admin', 'admin'],
  profiles_organization_users: ['super-admin', 'admin'],
  profiles_organization_locations: ['super-admin', 'admin'],
  profiles_organization_billing: ['super-admin', 'admin'],
  settings: ['user', 'super-admin', 'admin'],
  settings_notification: ['user', 'super-admin', 'admin'],
  account: ['user', 'super-admin', 'admin'],
  changePassword: ['user', 'super-admin', 'admin']
};

export const RISK_LEVELS = [
  'Imminent',
  'Very High Risk',
  'High Risk',
  'Moderate Risk',
  'Low Risk',
  'Very Low Risk',
  'Unresponsive',
  'Undetermined'
] as const;

export const LEVEL_OF_CARES = ['SUD', 'SUD+MH', 'MH'] as const;

export const SUCIDAL_SCALE = ['NSI-1', 'PSI-2', 'PSI-3', 'ASI-4', 'ASI-5'];

export const SESSION_KEYS = ['idToken', 'accessToken', 'roleData', 'userData'] as const;
