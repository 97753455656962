import { useRoutes } from 'react-router-dom';

// project-imports
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';

// ==============================|| ROUTES RENDER ||============================== //

import { useMemo } from 'react';
import useAuth from 'hooks/useAuth';
import MaintenanceError from 'pages/maintenance/error/404';

export const useRoleBasedRoutes = ({ roles, routes }: { roles: string[]; routes: any[] }) => {
  const filterRoutesByRoles = (route: any): any | null => {
    if (route.allowedRoles && !route.allowedRoles.some((role: any) => roles.includes(role))) {
      return null;
    }

    if (route.children) {
      const filteredChildren = route.children.map(filterRoutesByRoles).filter(Boolean);
      if (filteredChildren.length === 0) {
        return null;
      }
      return { ...route, children: filteredChildren };
    }

    return route;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filteredRoutes = useMemo(() => routes.map(filterRoutesByRoles).filter(Boolean), [roles, routes]);
  return useRoutes(filteredRoutes);
};

export default function ThemeRoutes() {
  const { isLoggedIn, user } = useAuth();
  const userRoles = user != null ? user.roles : !isLoggedIn ? ['super-admin'] : ['user'];
  const finalRoutes = useRoleBasedRoutes({
    roles: userRoles,
    routes: [
      LoginRoutes,
      MainRoutes,
      {
        path: '*',
        element: <MaintenanceError />
      }
    ]
  });
  return finalRoutes;
}
