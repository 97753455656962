import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import { People, Location, MessageText1 } from 'iconsax-react';
import { dispatch, useSelector } from 'store';
import { activeItem } from 'store/reducers/menu';
import useAuth from 'hooks/useAuth';
import { RolePagesAccess } from 'data/constant';

// ==============================|| HEADER PROFILE - SETTING TAB ||============================== //

const SettingTab = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { selectedLocationDetails } = useSelector((state) => state.organization);
  const handleListItemClick = (event: MouseEvent<HTMLDivElement>, path: string, index: number) => {
    event.preventDefault();
    navigate(path);
    dispatch(activeItem({ openItem: [] }));
  };
  const isRoleAccess = (arr: any): boolean => arr.some((item: string[]) => user?.roles?.includes(item));

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      {isRoleAccess(RolePagesAccess.profiles_organization) && (
        <>
          <ListItemButton
            selected={window.location.href.includes('/profiles/organization/users')}
            onClick={(event: MouseEvent<HTMLDivElement>) => handleListItemClick(event, '/profiles/organization/users', 0)}
          >
            <ListItemIcon>
              <People variant="Bulk" size={18} />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItemButton>
          <ListItemButton
            selected={window.location.href.includes('/profiles/organization/locations')}
            onClick={(event: MouseEvent<HTMLDivElement>) => handleListItemClick(event, '/profiles/organization/locations', 0)}
          >
            <ListItemIcon>
              <Location variant="Bulk" size={18} />
            </ListItemIcon>
            <ListItemText primary="Facilities" />
          </ListItemButton>
          {selectedLocationDetails?.isWebchatEnabled && selectedLocationDetails?.isWebchatEnabled !== null && (
            <ListItemButton
              selected={window.location.href.includes('/profiles/organization/webchat')}
              onClick={(event: MouseEvent<HTMLDivElement>) => handleListItemClick(event, '/profiles/organization/webchat', 0)}
            >
              <ListItemIcon>
                <MessageText1 variant="Bulk" size={18} />
              </ListItemIcon>
              <ListItemText primary="Webchat" />
            </ListItemButton>
          )}
        </>
      )}
    </List>
  );
};

export default SettingTab;
