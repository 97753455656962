/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';

// material-ui
import { Box, CircularProgress, Drawer, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project-imports
import IconButton from 'components/@extended/IconButton';
import MainCard from 'components/MainCard';

// assets
import { Add } from 'iconsax-react';

// types
import SimpleBar from 'components/third-party/SimpleBar';
import { dispatch, useSelector } from 'store';
import { getConversationChats, onMessageReceive } from 'store/reducers/chat';
import { History as HistoryProps } from 'types/chat';
import { TimeZones } from 'utils';
import ChatHistory from './ChatHistory';
import MessageToolBar from './MessageToolBar';
import { getCustomer, getUnreadNotificationCount, handleCloseDirectMesssageModal } from 'store/reducers/common';

// ==============================|| HEADER CONTENT - CUSTOMIZATION ||============================== //

const DirectMessageGlobal = () => {
  const { openDirectMessageModal, directMessageCustomerId, directMessageCustomer } = useSelector((state) => state.common);

  const userData = JSON.parse(JSON.stringify(directMessageCustomer));
  if (userData?.conversation) {
    userData.conversation['customer'] = {
      firstName: userData?.firstName?.trim(),
      lastName: userData?.lastName?.trim()
    };
  }

  const theme = useTheme();
  const hasMore = useRef(true);
  const isLoading = useRef<boolean>(true);
  const offset = useRef(0);
  const [pageSize] = useState(10);
  const [scrollHeight, setScrollHeight] = useState<number>(0);
  const [data, setData] = useState<HistoryProps[]>([]);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const { selectedLocationDetails, selectedLocation } = useSelector((state) => state.organization);
  const chatState = useSelector((state) => state.chat);

  useEffect(() => {
    if (directMessageCustomerId) {
      dispatch(getCustomer(directMessageCustomerId));
    }
  }, [directMessageCustomerId]);

  const handleToggle = () => {
    dispatch(handleCloseDirectMesssageModal());
  };

  useEffect(() => {
    if (directMessageCustomer) {
      isLoading.current = false;
      dispatch(getUnreadNotificationCount(selectedLocation));
    }
  }, [directMessageCustomer]);
  const getConversationChat = () => {
    if (isLoading.current || !hasMore.current) return;
    const query = `?limit=${pageSize}&offset=${offset.current}`;
    isLoading.current = true;
    if (chatContainerRef.current) setScrollHeight(chatContainerRef.current.scrollHeight);
    dispatch(getConversationChats(userData?.conversation?.id, query)).then(() => {
      offset.current = offset.current + pageSize;
      isLoading.current = false;
    });
  };

  const scrollToBottom = useCallback(() => {
    setTimeout(() => chatContainerRef?.current?.scrollTo(0, chatContainerRef.current.scrollHeight), 100);
  }, [chatContainerRef.current]);

  const handleScroll = () => {
    if (!chatContainerRef.current || isLoading.current) return;
    const scrollTop = chatContainerRef.current.scrollTop;
    const isAtTop = scrollTop === 0;

    if (isAtTop) {
      getConversationChat();
    }
  };
  // scroller
  useEffect(() => {
    if (chatContainerRef.current && userData?.conversation?.id) {
      chatContainerRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (chatContainerRef.current && userData?.conversation?.id) {
        chatContainerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [chatContainerRef.current, userData?.conversation?.id, openDirectMessageModal]);

  useEffect(() => {
    if (
      chatContainerRef?.current?.scrollHeight &&
      chatContainerRef?.current?.scrollHeight !== scrollHeight &&
      !isLoading.current &&
      chatContainerRef?.current?.scrollTop === 0
    ) {
      chatContainerRef.current.scroll(0, chatContainerRef.current.scrollHeight - scrollHeight);
    }
  }, [chatContainerRef?.current?.scrollHeight, isLoading.current]);

  // reset all state
  const resetStates = () => {
    offset.current = 0;
    setData([]);
    hasMore.current = true;
  };

  /**
   * on scroll loading new messages...
   */
  useEffect(() => {
    if (
      chatContainerRef?.current?.scrollHeight &&
      chatContainerRef?.current?.scrollHeight !== scrollHeight &&
      !isLoading.current &&
      chatContainerRef?.current?.scrollTop === 0
    ) {
      chatContainerRef.current.scroll(0, chatContainerRef.current.scrollHeight - scrollHeight);
    }
  }, [chatContainerRef?.current?.scrollHeight, isLoading.current]);

  useEffect(() => {
    if (openDirectMessageModal && directMessageCustomer && userData?.conversation?.id) {
      resetStates();
      getConversationChat();
    }
  }, [openDirectMessageModal, directMessageCustomer]);

  useEffect(() => {
    if (chatState.chats?.length < pageSize && !chatState.chatLoading && openDirectMessageModal) {
      hasMore.current = false;
    }
    setData((prev: any) => [...[...chatState.chats]?.reverse(), ...prev]);
  }, [chatState.chats]);

  const updateChats = useCallback(
    (newMessage: any) => {
      setData((prevState: any) => [...prevState, newMessage]);
    },
    [userData?.conversation?.id, openDirectMessageModal, directMessageCustomer]
  );

  // hanlde socket message receive envent
  useEffect(() => {
    if (chatState.receivedMessage?.id && openDirectMessageModal && directMessageCustomer) {
      if (userData?.conversation?.id === chatState.receivedMessage.conversation?.id) {
        const newMessage = { ...chatState.receivedMessage };
        newMessage.createdAt = new Date().toISOString();
        updateChats(newMessage);
      }
      dispatch(onMessageReceive({}));
    }
  }, [chatState.receivedMessage?.id, openDirectMessageModal, directMessageCustomer]);

  return (
    <>
      <Drawer
        sx={{ zIndex: 2001 }}
        anchor="right"
        onClose={handleToggle}
        open={openDirectMessageModal}
        PaperProps={{ sx: { width: { xs: 350, sm: 474 } } }}
      >
        {openDirectMessageModal && (
          <MainCard
            content={false}
            sx={{
              border: 'none',
              borderRadius: 0,
              height: '100vh',
              '& > div:first-child': {
                height: '100%'
              },
              '& > div:nth-child(2)': {
                height: '100%'
              }
            }}
          >
            <SimpleBar
              sx={{
                height: '100%',
                display: 'flex',
                alignContent: 'center',
                flexDirection: 'column',
                justifyContent: 'space-between',
                overflowX: 'inherit',
                '& .simplebar-content': {
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden'
                }
              }}
            >
              <Box sx={{ p: 2.5, height: '10%' }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1.5}>
                  <Typography variant="h5">Messages</Typography>
                  <IconButton color="secondary" sx={{ p: 0 }} onClick={handleToggle}>
                    <Add size={28} style={{ transform: 'rotate(45deg)' }} />
                  </IconButton>
                </Stack>
              </Box>
              <Grid
                container
                sx={{
                  position: 'relative',
                  bottom: 0,
                  height: '90%'
                }}
              >
                <Grid item xs={12} sx={{ paddingBottom: '200px', height: '100%' }}>
                  <div id="chatHistory" className="dashboard-chatHistory" ref={chatContainerRef}>
                    <Box sx={{ pl: 1, pr: 3, height: '100%' }}>
                      {isLoading.current && (
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <CircularProgress />
                        </Box>
                      )}
                      {!userData?.conversation?.id ? (
                        <Typography variant="h5" style={{ textAlign: 'center', marginTop: 15 }}>
                          {!userData?.conversation?.id
                            ? 'Please select user or selected user dose not have conversation record'
                            : 'Loading ...'}
                        </Typography>
                      ) : (
                        <ChatHistory
                          theme={theme}
                          userDetails={userData?.conversation}
                          data={data}
                          timeZone={TimeZones[selectedLocationDetails?.timeZone as keyof typeof TimeZones]}
                        />
                      )}
                    </Box>
                  </div>
                </Grid>
                <MessageToolBar
                  user={userData?.conversation}
                  isUnsubscribed={userData?.isUnsubscribed}
                  loading={isLoading.current}
                  setData={setData}
                  scrollToBottom={scrollToBottom}
                />
              </Grid>
            </SimpleBar>
          </MainCard>
        )}
      </Drawer>
    </>
  );
};

export default DirectMessageGlobal;
