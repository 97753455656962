import { useRef, useState, KeyboardEvent, MouseEvent } from 'react';
import { ClickAwayListener, Grid, IconButton, Popper, Stack, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { dispatch, useSelector } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { sendMessageTwillo } from 'store/reducers/chat';
import MainCard from 'components/MainCard';
import EmojiPicker, { EmojiClickData, SkinTones } from 'emoji-picker-react';
import { EmojiHappy, Send } from 'iconsax-react';

const MessageToolBar = ({ user, loading, setData, isUnsubscribed, scrollToBottom }: any) => {
  const theme = useTheme();
  const textInput = useRef(null);
  // handle new message form
  const [message, setMessage] = useState('');
  const { selectedLocation } = useSelector((state) => state.organization);
  const [anchorElEmoji, setAnchorElEmoji] = useState<any>();
  const handleOnSend = () => {
    if (message.trim() === '') {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Message required',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
    } else {
      const newMessage = {
        senderPhoneNumber: user.locationPhoneNumber,
        recipientPhoneNumber: user.customerPhoneNumber,
        body: message,
        createdAt: new Date().toISOString()
      };
      setData((prevState: any) => [...prevState, newMessage]);
      dispatch(
        sendMessageTwillo({
          locationId: selectedLocation,
          to: user.customerPhoneNumber,
          body: message
        })
      );
      setMessage('');
      scrollToBottom();
    }
  };

  const handleEnter = (event: KeyboardEvent<HTMLDivElement> | undefined) => {
    if (event?.key !== 'Enter') {
      return;
    }
    handleOnSend();
  };

  const handleOnEmojiButtonClick = (event: MouseEvent<HTMLButtonElement> | undefined) => {
    setAnchorElEmoji(anchorElEmoji ? null : event?.currentTarget);
  };

  // handle emoji
  const onEmojiClick = (emojiObject: EmojiClickData) => {
    setMessage(message + emojiObject.emoji);
  };

  const emojiOpen = Boolean(anchorElEmoji);
  const emojiId = emojiOpen ? 'simple-popper' : undefined;
  const handleCloseEmoji = () => {
    setAnchorElEmoji(null);
  };

  const isChattingDisabled = () => {
    return !user?.id || loading || isUnsubscribed;
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        p: 2,
        bgcolor: theme.palette.background.paper,
        borderTop: `1px solid ${theme.palette.divider}`,
        position: 'sticky',
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1
      }}
    >
      <Stack>
        <TextField
          inputRef={textInput}
          fullWidth
          multiline
          rows={2}
          placeholder={
            isUnsubscribed
              ? 'This user has been unsubscribed from their carrier and can no longer send or receive messages.'
              : 'Your message...'
          }
          value={message}
          onChange={(e) => setMessage(e.target.value.length <= 1 ? e.target.value.trim() : e.target.value)}
          onKeyPress={handleEnter}
          variant="standard"
          sx={{
            pr: 2,
            '& .MuiInput-root:before': { borderBottomColor: theme.palette.divider },
            '& .Mui-disabled': { '-webkit-text-fill-color': '#5b6b79!important' }
          }}
          disabled={isChattingDisabled()}
        />
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" sx={{ py: 2, ml: -1 }}>
            {!isChattingDisabled() && (
              <>
                <IconButton
                  ref={anchorElEmoji}
                  aria-describedby={emojiId}
                  onClick={handleOnEmojiButtonClick}
                  sx={{ opacity: 0.5 }}
                  size="medium"
                  color="secondary"
                >
                  <EmojiHappy />
                </IconButton>
                <Popper
                  id={emojiId}
                  open={emojiOpen}
                  anchorEl={anchorElEmoji}
                  disablePortal
                  style={{ zIndex: 1200 }}
                  popperOptions={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [-20, 125]
                        }
                      }
                    ]
                  }}
                >
                  <ClickAwayListener onClickAway={handleCloseEmoji}>
                    <MainCard elevation={8} content={false}>
                      <EmojiPicker onEmojiClick={onEmojiClick} defaultSkinTone={SkinTones.DARK} autoFocusSearch={false} />
                    </MainCard>
                  </ClickAwayListener>
                </Popper>
              </>
            )}
          </Stack>
          {!isChattingDisabled() && (
            <IconButton color="primary" disabled={!user?.id || loading} onClick={() => handleOnSend()} size="large" sx={{ mr: 1.5 }}>
              <Send />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Grid>
  );
};

export default MessageToolBar;
