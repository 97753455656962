import { DateTime } from 'luxon';

export const getUserRole = (roles: string[]) => {
  if (roles?.includes('super-admin')) {
    return 'super-admin';
  } else if (roles?.includes('admin')) {
    return 'admin';
  } else {
    return 'user';
  }
};

export const getNumberFromPhoneNumber = (number: string) => {
  if (!number) {
    return '';
  }
  const regex = /[^0-9]/g;
  return number.replace(regex, '');
};

export const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return '';
  const cleanedNumber = phoneNumber.replace(/[^\d]/g, ''); // Remove non-digit characters
  const areaCode = cleanedNumber.slice(1, 4);
  const firstPart = cleanedNumber.slice(4, 7);
  const secondPart = cleanedNumber.slice(7);

  return `(${areaCode}) ${firstPart}-${secondPart}`;
};

export const phoneNumberToSimpleFormat = (phoneNumber: string = '', internationalDialingCode = '+1'): string => {
  if (!phoneNumber) {
    return '';
  }
  if (phoneNumber.startsWith('+') && phoneNumber.length === 12) {
    return phoneNumber; //we assume it is already formatted
  }
  const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
  if (numericPhoneNumber.length !== 10) {
    return '';
  }
  const formattedPhoneNumber = `${internationalDialingCode}${numericPhoneNumber}`;
  return formattedPhoneNumber;
};

export const usFormattedDate = (createdAt: string) => {
  const date = new Date(createdAt);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  const usFormattedDate = `${month}/${day}/${year}`;

  return usFormattedDate;
};

export const recoveryDays = (dischargeDate: string) => {
  const formattedDate = new Date(dischargeDate);

  // Get the present date
  const presentDate = new Date();

  // Calculate the difference in days
  const timeDifference = presentDate.getTime() - formattedDate.getTime();
  const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

  return daysDifference > 0 ? daysDifference : 0;
};

export const TimeZones = {
  PacificStandardTime: 'America/Los_Angeles',
  MountainStandardTime: 'America/Denver',
  CentralStandardTime: 'America/Chicago',
  EasternStandardTime: 'America/New_York',
  Arizona: 'America/Phoenix',
  HawaiianStandardTime: 'pacific/honolulu'
} as const;

export const formatDateToUSFormat = (d: string) => {
  const date = new Date(d);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

export const getDateTime = (timestamp: any) => {
  const convertedTime = DateTime.fromObject(timestamp);
  const convertedFormatted = convertedTime.toFormat('LLL dd, yyyy, h:mm a');
  return convertedFormatted;
};

export const getFileTypeFromUrl = (url: string): string => {
  // Extracting the file extension from the URL
  const fileExtension = url.substring(url.lastIndexOf('.') + 1, url.length);

  // File type mappings based on common file extensions
  const fileTypeMapping: Record<string, string> = {
    '.txt': 'document',
    '.csv': 'document',
    '.pdf': 'document',
    '.doc': 'document',
    '.docx': 'document',
    '.jpg': 'image',
    '.jpeg': 'image',
    '.png': 'image',
    '.gif': 'image',
    '.bmp': 'image',
    '.mp4': 'video',
    '.avi': 'video',
    '.mkv': 'video',
    '.mov': 'video',
    '.mp3': 'audio',
    '.wav': 'audio',
    '.ogg': 'audio',
    '.flac': 'audio',
    '.aac': 'audio',
    '.ico': 'emoji'
  };

  // Checking if the file extension is in the mapping
  const fileType = fileTypeMapping['.' + fileExtension] || 'text';

  return fileType;
};

export const getFileName = (urlString: string) => {
  const url = new URL(urlString);
  const pathname = url.pathname;
  const segments = pathname.split('/');
  return segments[segments.length - 1];
};

export const calculatePastTime = (selectedValue: any) => {
  const [timeValue, timeUnit] = selectedValue.split('_'); // Splitting the value

  let pastTime = new Date(); // Current time

  // Calculate past time based on selected option
  switch (timeUnit) {
    case 'hours':
      pastTime.setHours(pastTime.getHours() - parseInt(timeValue, 10));
      break;
    case 'days':
      pastTime.setDate(pastTime.getDate() - parseInt(timeValue, 10));
      break;
    case 'month':
      pastTime.setMonth(pastTime.getMonth() - parseInt(timeValue, 10));
      break;
    default:
      break;
  }

  return pastTime; // Return the calculated past time
};

export const validatePhoneNumber = (number: string) => {
  const patterns = [
    /^\d{10}$/, // 9491234567
    /^\d{3}-\d{3}-\d{4}$/, // 949-123-4567
    /^\(\d{3}\) \d{3}-\d{4}$/, // (949) 123-4567
    /^\d{3}\.\d{3}\.\d{4}$/, // 949.123.4567
    /^\+\d{1,2}\d{10}$/ // +19491234567
  ];
  const isValid = patterns.some((pattern) => pattern.test(number));

  return isValid;
};

export const capitalizeFirstLetter = (str: string = '') => {
  return str.length > 0 ? str[0].toUpperCase() + (str.slice(1) || '').toLowerCase() : '';
};

export const removeDuplicateConversation = (arr: any): any => {
  const latestItems: any = {};

  for (const item of arr) {
    const existingItem = latestItems[item.id];
    if (!existingItem || new Date(item?.lastMessage?.createdAt) > new Date(existingItem?.lastMessage?.createdAt)) {
      latestItems[item.id] = item;
    }
  }

  const resultArray = Object.values(latestItems).sort(
    (a: any, b: any) => (new Date(b?.lastMessage?.createdAt as any) as any) - (new Date(a?.lastMessage?.createdAt as any) as any)
  );

  return resultArray;
};

export const dateToUSFormat = (d: string) => {
  if (!d) {
    return '';
  }
  const date = new Date(d);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = new Intl.DateTimeFormat('en-US', options as any).format(date);
  return formattedDate;
};

export const getAveResponseTypeColor = (average: number) =>
  average <= 1 ? '#c33b3b' : average <= 2 ? '#e76a6a' : average <= 3 ? '#E88447' : average <= 4 ? '#329f6b' : '#127463';

export const validateUserCsv = ({ parsedData }: any) => {
  const invalidItems: any = [];

  const numbers: any = [];
  parsedData.data?.forEach((item: any) => {
    const errors: string[] = [];
    Object.entries(item).forEach(([key, value]: any) => {
      const columnName = key?.trim().toLowerCase();
      if (!columnName) {
        return;
      }
      if (columnName === 'cell phone' || columnName === 'phone number') {
        const formatedNumber = phoneNumberToSimpleFormat(value);
        if (!value || !validatePhoneNumber(value)) {
          errors.push('Invalid phone number');
        } else if (numbers.includes(formatedNumber)) {
          errors.push('Duplicate phone number');
        }
        numbers.push(formatedNumber);
      }
      if (columnName === 'first name' && !value) {
        errors.push('First name is missing');
      }
      if (columnName === 'last name' && !value) {
        errors.push('Last name is missing');
      }
    });
    if (errors.length > 0) {
      invalidItems.push({ ...item, errors });
    }
  });

  return { invalidItems };
};

export const getMassCustomerUploadErrorMessage = (item: any) =>
  Object.entries(item).find((d: any) => ['phone number', 'cell phone'].includes(d[0]?.trim()?.toLowerCase()))?.[1];

export const convertMillisecondsToMinutes = (milliseconds: number) => {
  return Math.round(Math.round(milliseconds / 1000) / 60);
};

export const getResponseExportColors = (responseType: string) => {
  const colorMap: { [key: string]: string } = {
    'strongly agree': 'B7D7A8',
    agree: 'D8EAD3',
    neutral: 'F9CB9C',
    disagree: 'F4CCCC',
    'strongly disagree': 'EA9999',
    responded: 'B7D7A8',
    'not responded': 'EA9999',
    'very rarely': 'C33B3B',
    rarely: 'E76A6A',
    occasionally: 'E88447',
    frequently: '329F6B',
    'very frequently': '127463',
    'very unlikely': 'C33B3B',
    unlikely: 'E76A6A',
    likely: '329F6B',
    'very likely': '127463',
    'very poor': 'C33B3B',
    poor: 'E76A6A',
    acceptable: 'E88447',
    good: '329F6B',
    'very good': '127463',
    'very dissatisfied': 'C33B3B',
    'somewhat dissatisfied': 'E76A6A',
    'Somewhat satisfied': '329F6B',
    'very satisfied': '127463',
    no: 'EA9999',
    yes: 'B7D7A8',
    'no response': 'B0B3B2',
    default: 'FFFFFF'
  };

  const normalizedResponseType = responseType.toLowerCase();
  return colorMap[normalizedResponseType] || colorMap['default'];
};

export const getResponseTypeOrder = (responseValues: string[]) => {
  const sortOrder: { [key: string]: number } = {
    'strongly disagree': 1,
    disagree: 2,
    neutral: 3,
    agree: 4,
    'strongly agree': 5,
    'very rarely': 1,
    rarely: 2,
    occasionally: 3,
    frequently: 4,
    'very frequently': 5,
    'very unlikely': 1,
    unlikely: 2,
    likely: 4,
    'very likely': 5,
    'very poor': 1,
    poor: 2,
    acceptable: 3,
    good: 4,
    'very good': 5,
    'very dissatisfied': 1,
    'somewhat dissatisfied': 2,
    'somewhat satisfied': 4,
    'very satisfied': 5,
    no: 1,
    yes: 5
  };

  return responseValues.sort((a: string, b: string) => {
    const [typeA] = a.split(' : ');
    const [typeB] = b.split(' : ');
    const lowercaseTypeA = typeA.trim().toLowerCase();
    const lowercaseTypeB = typeB.trim().toLowerCase();
    return sortOrder[lowercaseTypeA] - sortOrder[lowercaseTypeB];
  });
};
