import { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Stack,
  Autocomplete,
  TextField,
  CircularProgress,
  useMediaQuery,
  Paper,
  Select,
  MenuItem,
  Typography,
  FormControl,
  InputLabel
} from '@mui/material';

// third-party

// assets
import { useSelector } from 'react-redux';
import { dispatch } from 'store';
import {
  getLocationByOrgId,
  setSelectedLocation,
  getOrganizations,
  resetOrganizationState,
  getOrganizationSuccess,
  getLocationByOrgIdSuccess,
  setSelectedOrgId
} from 'store/reducers/organization';
import useAuth from 'hooks/useAuth';
import useLocalStorage from 'hooks/useLocalStorage';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { resetChatsState } from 'store/reducers/chat';
import { resetWorkflowStats } from 'store/reducers/workflows';
import { resetCommonStats } from 'store/reducers/common';
import { useTheme } from '@mui/material';
import { OutlinedInput } from '@mui/material';

// ==============================|| HEADER CONTENT - CUSTOMIZATION ||============================== //

const OrganizationSettings = () => {
  const theme = useTheme();
  const smDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const inputRef: any = useRef('');
  const [selectedOrganization, setSelectedOrganization] = useState<any>('');
  const [selectedLocationValue, setSelectedLocationValue] = useState<any>(null);
  const [localStoreOrgId, setOrganizationId] = useLocalStorage('organizationId');
  const [localStoreLocationId, setLocationId] = useLocalStorage('locationId');
  const [selectedOrgMobile, setSelectedOrgMobile] = useState('');
  const [selectedLocationMobile, setSelectedLocationMobile] = useState('');
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const orgId = searchParams.get('organization');
  const locId = searchParams.get('location');
  const organizationId = orgId ?? location?.state?.oId ?? localStoreOrgId;
  const locationId = locId ?? location?.state?.locId ?? localStoreLocationId;
  const { organizations, loading, locations } = useSelector((state: any) => state.organization);
  const { user, isLoggedIn, hanldeSetUserRole } = useAuth();

  const handleResetStates = async () => {
    await dispatch(resetChatsState());
    await dispatch(resetWorkflowStats());
    await dispatch(resetCommonStats());
    navigate('/dashboard');
  };

  const handleOrganizationChange = (data: any) => {
    if (data?.id) {
      setSelectedOrgMobile(data?.name);
      setSelectedOrganization(data);
      setOrganizationId(data?.id);
      if (user?.isSuperAdmin) {
        dispatch(getLocationByOrgId(data.id));
      } else {
        const role = user?.rolesData?.filter((r: any) => r?.organization?.id === data?.id)?.[0];
        dispatch(getLocationByOrgIdSuccess(role?.locations));
        hanldeSetUserRole(role.role);
      }
    }
    if (selectedOrganization && selectedOrganization.id !== data?.id) {
      handleResetStates();
    }
  };

  const handleOnLocationSelect = (data: any) => {
    setSelectedLocationMobile(data.name);
    if (data?.id) {
      setLocationId(data?.id);
      const location = locations.filter((l: any) => l.id === data?.id)?.[0];
      setSelectedLocationValue(data);
      dispatch(setSelectedLocation(location ?? {}));
    }
    if (selectedLocationValue && selectedLocationValue.id !== data?.id) {
      handleResetStates();
    }
  };

  // loading organizations globaly
  useEffect(() => {
    if (isLoggedIn && organizations.length === 0) {
      if (user?.isSuperAdmin) {
        dispatch(getOrganizations());
      } else {
        const organizations = user?.rolesData?.map((r: any) => r?.organization);
        dispatch(getOrganizationSuccess(organizations));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations.length, user, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && organizations?.length > 0) {
      let defaultOrg = organizations[0];
      if (organizationId) {
        const getSelectedOrg = organizations.filter((o: any) => o?.id === organizationId)?.[0];
        if (getSelectedOrg) defaultOrg = getSelectedOrg;
      }
      if (defaultOrg?.id) {
        dispatch(setSelectedOrgId(defaultOrg?.id));
        setSelectedOrganization({ id: defaultOrg?.id, label: defaultOrg?.name });
        setSelectedOrgMobile(defaultOrg?.name);
      }
      if (user?.isSuperAdmin) {
        dispatch(getLocationByOrgId(defaultOrg?.id));
      } else {
        const role = user?.rolesData?.filter((r: any) => r?.organization?.id === defaultOrg?.id)?.[0];
        dispatch(getLocationByOrgIdSuccess(role?.locations));
        hanldeSetUserRole(role.role);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations?.length]);

  useEffect(() => {
    if (isLoggedIn && locations?.length > 0) {
      let sortedLocationArr = locations?.slice().sort((a: any, b: any) => a.name.localeCompare(b.name));
      let defaultLocation = sortedLocationArr[0];

      if (locationId) {
        const getSelectedlocation = locations.filter((l: any) => l?.id === locationId)?.[0];
        if (getSelectedlocation) defaultLocation = getSelectedlocation;
      }

      if (!locationId && user?.locationIds) {
        const getSelectedlocation = locations.filter((l: any) => l?.id === user?.locationIds?.split(',')?.[0])?.[0];
        if (getSelectedlocation) defaultLocation = getSelectedlocation;
      }

      handleOnLocationSelect({ id: defaultLocation?.id, label: defaultLocation?.name });
    } else {
      dispatch(setSelectedLocation(null));
      setSelectedLocationValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations?.length, selectedOrganization]);

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(resetOrganizationState());
    }
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    return <></>;
  }

  return (
    <>
      <Grid
        container
        spacing={2.5}
        p={smDevice ? 0.5 : 2}
        sx={{
          justifyContent: { xs: 'space-between', sm: 'space-betweeen' }
        }}
      >
        {organizations?.length > 0 ? (
          <Grid item lg={6} sm={6} xs={6}>
            <Stack spacing={1}>
              {!smDevice ? (
                <Autocomplete
                  id="organization"
                  size="medium"
                  PaperComponent={({ children }) => <Paper style={{ maxWidth: '200px', alignContent: 'center' }}>{children}</Paper>}
                  options={[...organizations]
                    .sort((a: any, b: any) => a.name.localeCompare(b.name))
                    .map((item: any) => ({ id: item.id, label: item.name }))}
                  loading={loading}
                  disabled={loading}
                  value={selectedOrganization}
                  getOptionLabel={(option) => option?.label || ''}
                  onChange={(_, data: any) => handleOrganizationChange(data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputRef={inputRef}
                      label="Organization"
                      placeholder="Select organizations"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                      sx={{
                        '& .MuiInputBase-input': {
                          WebkitTextFillColor: `${theme.palette.secondary.main} !important`
                        },
                        '& .Mui-disabled': {
                          color: `${theme.palette.secondary.main} !important`
                        }
                      }}
                    />
                  )}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      padding: '2px 5px!important'
                    },
                    '& .MuiAutocomplete-tag': {
                      bgcolor: 'primary.lighter',
                      border: '1px solid',
                      borderColor: 'primary.light',
                      '& .MuiSvgIcon-root': {
                        color: 'primary.main',
                        '&:hover': {
                          color: 'primary.dark'
                        }
                      }
                    },
                    '& .MuiFormLabel-root': {
                      background: 'transparent!important'
                    }
                  }}
                />
              ) : (
                <FormControl
                  sx={{
                    '& .MuiFormLabel-root': {
                      background: 'transparent !important'
                    }
                  }}
                >
                  <InputLabel shrink={true} id="organization-label">
                    Organization
                  </InputLabel>
                  <Select
                    id="organization"
                    size="small"
                    placeholder="Select organization"
                    input={<OutlinedInput label="Organization" />}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 400,
                          width: 200
                        }
                      }
                    }}
                    value={!selectedOrganization?.label ? selectedOrgMobile : selectedOrganization?.label}
                    renderValue={(selected: any) => {
                      if (!selected) {
                        return <Typography variant="subtitle2">{selectedOrgMobile}</Typography>;
                      }

                      return (
                        <Typography variant="subtitle2">{!selectedOrganization.label ? selected : selectedOrganization.label}</Typography>
                      );
                    }}
                    sx={{
                      '& .MuiSelect-select': {
                        borderRadius: '8px'
                      },

                      '& .MuiMenuItem-root': {
                        display: 'flex', // Enable flex layout
                        alignItems: 'left'
                      }
                    }}
                  >
                    {[...organizations]
                      .sort((a: any, b: any) => a.name.localeCompare(b.name))
                      .map((item: any) => (
                        <MenuItem onClick={() => handleOrganizationChange(item)} key={item.name} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            </Stack>
          </Grid>
        ) : null}
        <Grid item lg={6} sm={6} xs={6}>
          <Stack spacing={1}>
            {!smDevice ? (
              <Autocomplete
                id="location"
                size="medium"
                options={[...locations]
                  .sort((a: any, b: any) => a.name.localeCompare(b.name))
                  .map((item: any) => ({ id: item.id, label: item.name }))}
                loading={loading}
                disabled={loading}
                value={selectedLocationValue}
                getOptionLabel={(option) => option?.label || ''}
                onChange={(_, data: any) => handleOnLocationSelect(data)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select facilities"
                    label="Facility"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                    sx={{
                      '& .MuiInputBase-input': {
                        WebkitTextFillColor: `${theme.palette.secondary.main} !important`
                      },
                      '& .Mui-disabled': {
                        color: `${theme.palette.secondary.main} !important`
                      }
                    }}
                  />
                )}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    padding: '2px 5px!important'
                  },
                  '& .MuiAutocomplete-tag': {
                    bgcolor: 'primary.lighter',
                    border: '1px solid',
                    borderColor: 'primary.light',
                    '& .MuiSvgIcon-root': {
                      color: 'primary.main',
                      '&:hover': {
                        color: 'primary.dark'
                      }
                    }
                  },
                  '& .MuiFormLabel-root': {
                    background: 'transparent!important'
                  }
                }}
              />
            ) : loading ? (
              <TextField
                placeholder="Select facilities"
                size="small"
                label="Select facilities"
                InputProps={{
                  endAdornment: <CircularProgress color="inherit" size={20} />
                }}
                sx={{
                  '& .MuiInputBase-input': {
                    WebkitTextFillColor: `${theme.palette.secondary.main} !important`
                  },
                  '& .Mui-disabled': {
                    color: `${theme.palette.secondary.main} !important`
                  }
                }}
              />
            ) : (
              <FormControl
                sx={{
                  '& .MuiFormLabel-root': {
                    background: 'transparent !important'
                  }
                }}
              >
                <InputLabel id="demo-simple-select-label" shrink={true}>
                  Facility
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="location"
                  size="small"
                  placeholder="Select facility"
                  input={<OutlinedInput label="Facility" />}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 400,
                        width: 200
                      }
                    }
                  }}
                  value={!selectedLocationValue?.label ? selectedLocationMobile : selectedLocationValue?.label}
                  renderValue={(selected: any) => {
                    if (!selected) {
                      return <Typography variant="subtitle2">{selectedLocationMobile}</Typography>;
                    }

                    return (
                      <Typography variant="subtitle2">{!selectedLocationValue?.label ? selected : selectedLocationValue.label}</Typography>
                    );
                  }}
                  sx={{
                    '& .MuiSelect-select': {
                      borderRadius: '8px'
                    },

                    '& .MuiMenuItem-root': {
                      display: 'flex',
                      alignItems: 'left'
                    }
                  }}
                >
                  {[...locations]
                    .sort((a: any, b: any) => a.name.localeCompare(b.name))
                    .map((item: any) => (
                      <MenuItem onClick={() => handleOnLocationSelect(item)} key={item.name} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default OrganizationSettings;
