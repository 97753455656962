import { useCallback, useEffect, useRef } from 'react';

// material-ui
import { Box, Card, CardContent, CardMedia, Grid, IconButton, Stack, Theme, Typography } from '@mui/material';

// project-imports
import Avatar from 'components/@extended/Avatar';
import UserAvatar from './UserAvatar';
// import ChatMessageAction from './ChatMessageAction';
// import IconButton from 'components/@extended/IconButton';
import useAuth from 'hooks/useAuth';

// assets
// import { Edit } from 'iconsax-react';

// types
import EllipsisText from 'components/EllipsisText';
import { useChatContext } from 'contexts/ChatContext';
import { AddCircle, Document, TickCircle } from 'iconsax-react';
import { DateTime } from 'luxon';
import { Conversation, History } from 'types/chat';
import { ThemeMode } from 'types/config';
import { getFileName } from 'utils';
// ==============================|| CHAT - HISTORY ||============================== //

interface ChatHistoryProps {
  data: History[];
  theme: Theme;
  userDetails: Conversation;
  timeZone: string;
}

// "text", "image", "video", "audio", "emoji", "document"
const historyContentElement = (type: string, body: string, handleOnShowMedia: any, direction = 'outbound') => {
  switch (type) {
    case 'video':
      return (
        <CardMedia component="video" height="194" sx={{ cursor: 'pointer' }} src={body} onClick={() => handleOnShowMedia({ type, body })} />
      );
    case 'image':
      return (
        <CardMedia
          component="img"
          height="194"
          sx={{ cursor: 'pointer' }}
          image={body}
          alt="Paella dish"
          onClick={() => handleOnShowMedia({ type, body })}
        />
      );
    case 'audio':
      return <CardMedia component="audio" sx={{ cursor: 'pointer' }} src={body} controls />;
    case 'document':
      return (
        <a
          href={body}
          download="file.pdf" // Set the desired file name here
          className="document-media-preview"
        >
          <Document
            fontSize="large"
            color={direction === 'outbound' ? '#fff' : '#000'}
            variant="Bold"
            style={{
              width: '60px',
              height: '60px',
              margin: '0px 8px'
            }}
          />
          <EllipsisText text={getFileName(body)} maxCharacters={50} color={direction === 'outbound' ? '#fff' : '#000'} />
        </a>
      );
    default:
      return (
        <Typography variant="h6" color="textPrimary" sx={{ overflowWrap: 'anywhere' }}>
          {body}
        </Typography>
      );
  }
};

const ChatHistory = ({ data, theme, userDetails, timeZone }: ChatHistoryProps) => {
  // scroll to bottom when new message is sent or received
  const wrapper = useRef(document.createElement('div'));
  const { user } = useAuth();
  const { setIsOpenMediaPreview, setMediaObject } = useChatContext();
  const el = wrapper.current;
  const scrollToBottom = useCallback(() => {
    el.scrollIntoView(false);
  }, [el]);

  useEffect(() => {
    if (data.length <= 10) {
      scrollToBottom();
    }
  }, [data.length, scrollToBottom]);

  const getTime = (timestamp: any) => {
    const convertedTime = DateTime.fromISO(timestamp, { zone: timeZone });
    const convertedFormatted = convertedTime.toFormat('LLL dd, yyyy, h:mm a');
    return convertedFormatted;
  };

  const handleOnShowMedia = (mediaData: History) => {
    setMediaObject(mediaData);
    setIsOpenMediaPreview(true);
  };

  return (
    <Grid container paddingTop={0.3} ref={wrapper}>
      {data.map((history, index) => (
        <Grid item xs={12} key={index} sx={{ maxWidth: '85vw' }}>
          {history?.senderPhoneNumber !== userDetails?.customerPhoneNumber ? (
            <Stack spacing={1.25} direction="row">
              <Grid container spacing={1} justifyContent="flex-end">
                <Grid item xs={2} md={3} xl={4} />

                <Grid item xs={10} md={9} xl={8}>
                  <Stack direction="row" justifyContent="flex-end" alignItems="flex-start">
                    {/* <ChatMessageAction index={index} />
                    <IconButton size="small" color="secondary">
                      <Edit />
                    </IconButton> */}
                    <Card
                      sx={{
                        display: 'inline-block',
                        float: 'right',
                        bgcolor: history.type === 'audio' ? 'transparent' : theme.palette.primary.main,
                        boxShadow: 'none',
                        ml: 1
                      }}
                    >
                      <CardContent
                        sx={
                          history.type === 'text'
                            ? { p: 1, pb: '8px !important', width: 'fit-content', ml: 'auto' }
                            : history.type === 'audio'
                            ? { p: 0.5, pb: '4px !important', width: '300px', ml: 'auto', background: 'transparent' }
                            : { p: 0.5, pb: '4px !important', width: 'fit-content', ml: 'auto' }
                        }
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={12} sx={{ '.MuiTypography-h6': { color: '#fff' } }}>
                            {historyContentElement((history.type as string) ?? '', history.body as string, handleOnShowMedia)}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Typography align="right" variant="subtitle2" color="textSecondary">
                    {getTime(history.createdAt)}
                  </Typography>
                </Grid>
              </Grid>
              <div className="avatar-status-container">
                {/* TODO - replace with message sent satus */}
                <IconButton
                  size="small"
                  sx={{ position: 'absolute', right: -8, top: -10, zIndex: 99 }}
                  color={history?.status === 'delivered' ? 'success' : 'error'}
                >
                  {history?.status === 'delivered' ? (
                    <TickCircle size={16} style={{ color: theme.palette.success.main }} variant="Bold" />
                  ) : history?.status === 'failed' || history?.status === 'undelivered' || history?.status === 'canceled' ? (
                    <AddCircle size={16} style={{ color: theme.palette.error.main, transform: 'rotate(40deg)' }} variant="Bold" />
                  ) : (
                    <Box
                      sx={{
                        width: 12,
                        height: 12,
                        borderRadius: '50%',
                        bgcolor: theme.palette.grey[400]
                      }}
                    />
                  )}
                </IconButton>

                {user?.given_name ? (
                  <UserAvatar
                    user={{
                      online_status: 'active',
                      avatar: 'avatar-1.png',
                      name:
                        user?.given_name || user?.family_name
                          ? user?.given_name?.split(' ')[0][0] + ' ' + user?.family_name?.split(' ')[0][0]
                          : ''
                    }}
                  />
                ) : (
                  <Avatar alt="profile user" />
                )}
              </div>
            </Stack>
          ) : (
            <Stack direction="row" spacing={1.25} alignItems="flext-start">
              <UserAvatar
                user={{
                  online_status: 'active',
                  avatar: 'avatar-1.png',
                  name: userDetails?.customer?.firstName + ' ' + userDetails?.customer?.lastName
                }}
              />

              <Grid container>
                <Grid item xs={12} sm={7}>
                  <Card
                    sx={{
                      display: 'inline-block',
                      float: 'left',
                      bgcolor:
                        history.type === 'audio'
                          ? 'transparent'
                          : theme.palette.mode === ThemeMode.DARK
                          ? 'background.background'
                          : 'common.white',
                      boxShadow: 'none'
                    }}
                  >
                    <CardContent
                      sx={
                        history.type === 'text'
                          ? { p: 1, pb: '8px !important' }
                          : history.type === 'audio'
                          ? { p: 0.5, pb: '4px !important', width: '300px', background: 'transparent' }
                          : { p: 0.5, pb: '4px !important' }
                      }
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          {historyContentElement((history.type as string) ?? '', history.body as string, handleOnShowMedia, 'inbound')}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <Typography align="left" variant="subtitle2" color="textSecondary">
                    {getTime(history?.createdAt)}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default ChatHistory;
