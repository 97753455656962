// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
// import mockAxios from 'utils/mockAxios';
import axios from 'utils/axios';
import { dispatch } from '../index';

// types
import { ChatStateProps } from 'types/chat';
import { enqueueSnackbar } from 'notistack';
import { errorSnackConfig, succesSnackConfig } from '../../config';
import { CONVERSTATION_ITEMS_LIMIT } from 'data/constant';

const initialState: ChatStateProps = {
  error: null,
  chats: [],
  chatCount: 0,
  user: {},
  users: [],
  conversations: [],
  groups: [],
  conversation: {},
  chatLoading: false,
  conversationCount: 0,
  groupCount: 0,
  groupLoading: false,
  conversationLoading: false,
  receivedMessage: {},
  offest: ''
};

// ==============================|| SLICE - CHAT ||============================== //

const chat = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
      state.chatLoading = false;
      state.conversationLoading = false;
      state.groupLoading = false;
    },

    // GET USER
    getUserSuccess(state, action) {
      state.user = action.payload;
    },

    // GET USER CHATS
    getUserChatsSuccess(state, action) {
      state.chats = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.users = action.payload;
    },

    // GET USER
    getConversationSuccess(state, action) {
      state.user = action.payload;
    },

    getConversationChats(state) {
      state.chatLoading = true;
      state.chats = [];
    },

    getConversationChatsSuccess(state, action) {
      state.chats = action.payload?.messages || [];
      state.chatCount = action.payload?.count || 0;
      state.chatLoading = false;
    },
    getConversations(state, action) {
      state.conversationLoading = action.payload;
      state.conversations = [];
    },
    getConversationsSuccess(state, action) {
      state.conversationLoading = false;
      const { offest, conversations } = action.payload;
      if (state.offest === offest && state.conversations?.[0]?.id === conversations[0]?.id) {
        return;
      }
      state.conversations = conversations;
      state.conversationCount = action.payload.count;
      state.offest = offest;
    },
    updateConversationStatus(state, action) {
      if (action.payload) {
        state.conversations = state.conversations.map((c) => {
          if (c.id === action.payload) {
            c.lastReadMessage = c.lastMessage?.id;
          }
          return c;
        });
      }
    },
    getGroups(state) {
      state.groupLoading = true;
    },
    getGroupsSuccess(state, action) {
      state.groups = (action.payload || [])?.sort((a: any, b: any) => a?.name?.localeCompare(b?.name));
      state.groupCount = action.payload.length;
      state.groupLoading = false;
    },
    onMessageReceive(state, action) {
      state.receivedMessage = action.payload;
    },
    resetChatsState(state) {
      state.conversations = [];
    }
  }
});

// Reducer
export default chat.reducer;

// export function getUser(id: number) {
//   return async () => {
//     try {
//       const response = await mockAxios.post('/api/chat/users/id', { id });
//       dispatch(chat.actions.getUserSuccess(response.data));
//     } catch (error) {
//       dispatch(chat.actions.hasError(error));
//     }
//   };
// }

// export function getUserChats(user: string | undefined) {
//   return async () => {
//     try {
//       const response = await mockAxios.post('/api/chat/filter', { user });
//       dispatch(chat.actions.getUserChatsSuccess(response.data));
//     } catch (error) {
//       dispatch(chat.actions.hasError(error));
//     }
//   };
// }

export function getConversationChats(conversationId: string | undefined, query: string) {
  return async () => {
    try {
      dispatch(chat.actions.getConversationChats());
      const response = await axios.get(`conversations/${conversationId}/getMessages${query}`);
      dispatch(chat.actions.getConversationChatsSuccess(response.data?.payload));
    } catch (error: any) {
      dispatch(chat.actions.hasError(error));
      enqueueSnackbar(error.error?.message ?? 'Failed to get Messages.', errorSnackConfig as any);
    }
  };
}

// export function insertChat(chat: any) {
//   return async () => {
//     try {
//       await mockAxios.post('/api/chat/insert', chat);
//     } catch (error) {
//       dispatch(chat.actions.hasError(error));
//     }
//   };
// }

export function sendMessageTwillo(data: any) {
  return async () => {
    try {
      return await axios.post('/twilio/sendMessage', data);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to send message.', errorSnackConfig as any);
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function sendGroupMessageTwillo(data: any) {
  return async () => {
    try {
      await axios.post('/twilio/sendGroupMessage', data);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to send message.', errorSnackConfig as any);
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function uploadFile(data: any) {
  return async () => {
    try {
      const response = await axios.post('/file/upload', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response?.data;
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to send file.', errorSnackConfig as any);
      dispatch(chat.actions.hasError(error));
    }
  };
}

// export function getUsers() {
//   return async () => {
//     try {
//       const response = await mockAxios.get('/api/chat/users');
//       dispatch(chat.actions.getUsersSuccess(response.data.users));
//     } catch (error: any) {
//       enqueueSnackbar(error.error?.message ?? 'Failed to get users.', errorSnackConfig as any);
//       dispatch(chat.actions.hasError(error));
//     }
//   };
// }

export function getConversations({
  locationId,
  isLoader = true,
  filter = '',
  conversationLimit = CONVERSTATION_ITEMS_LIMIT,
  conversationOffset = 0,
  search = '',
  selectedGroup = '',
  conversationId = '',
  facilities = ''
}: any) {
  return async () => {
    try {
      dispatch(chat.actions.getConversations(isLoader));
      const response = await axios.get(
        `/conversations?locationId=${locationId}&filter=${filter}&limit=${conversationLimit}&offset=${conversationOffset}&search=${search}&groupId=${
          selectedGroup === 'all' ? '' : selectedGroup
        }&conversationId=${conversationId}&facilities=${facilities}`
      );
      return dispatch(chat.actions.getConversationsSuccess(response.data?.payload));
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to get conversations.', errorSnackConfig as any);
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getGroupConversations({ offset, limit, groupId, search = '' }: any) {
  return async () => {
    try {
      const response = await axios.get(
        `/conversations/getConversationsByGroupId/${groupId}?limit=${limit}&offset=${offset}&search=${search}`
      );
      return response.data?.payload;
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to get conversations.', errorSnackConfig as any);
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getGroups(locationId: string) {
  return async () => {
    try {
      dispatch(chat.actions.getGroups());
      const response = await axios.get(`/group/${locationId}`);
      dispatch(chat.actions.getGroupsSuccess(response.data?.payload));
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to get groups.', errorSnackConfig as any);
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function createGroup(data: any) {
  return async () => {
    try {
      await axios.post('/group', data);
      enqueueSnackbar('Group created successfully.', succesSnackConfig as any);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to create group!', errorSnackConfig as any);
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function bulkImportCustomersIntoGroupByCSV(data: any) {
  return async () => {
    try {
      await axios.post('/group/bulkImportCustomersIntoGroupByCSV', data);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to create group!', errorSnackConfig as any);
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getMassTextPrice(orgId: string) {
  return async () => {
    try {
      const response = await axios.get(`/group/${orgId}/calculateMassTextingPrice`);
      return response?.data;
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed To get Price!', errorSnackConfig as any);
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getScheduledMessage(orgId: string, status: string = '') {
  return async () => {
    try {
      const response = await axios.get(`/group/${orgId}/scheduledMessage?status=${status}`);
      return response?.data;
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed To get scheduled message!', errorSnackConfig as any);
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function createParticipant(data: any) {
  return async () => {
    try {
      await axios.post('/conversations', data);
      enqueueSnackbar('Participant created successfully.', succesSnackConfig as any);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to create Participant!', errorSnackConfig as any);
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function deleteGroup(groupId: string) {
  return async () => {
    try {
      await axios.delete(`/group/${groupId}`);
      enqueueSnackbar('Group Deleted Successfully.', succesSnackConfig as any);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to Deleted Group!', errorSnackConfig as any);
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function deleteConversation(conversationId: string) {
  return async () => {
    try {
      await axios.delete(`/conversations/${conversationId}`);
      enqueueSnackbar('Participant Deleted Successfully.', succesSnackConfig as any);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to Deleted Participant!', errorSnackConfig as any);
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function confirmPaymentGroupMessage(data: any) {
  return async () => {
    try {
      await axios.post('/twilio/confirmPaymentAndsendGroupMessage', data);
      enqueueSnackbar('Success! Message added in the queue.', succesSnackConfig as any);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to send message.', errorSnackConfig as any);
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function unsubscribeConversation(id: string) {
  return async () => {
    try {
      await axios.put(`/customers/${id}/unsubscribe`);
      enqueueSnackbar('Customer unsubscribe Successfully.', succesSnackConfig as any);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to unsubscribe customer.', errorSnackConfig as any);
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function resubscribeConversation(id: string) {
  return async () => {
    try {
      await axios.put(`/customers/${id}/resubscribe`);
      enqueueSnackbar('Customer resubscribe Successfully.', succesSnackConfig as any);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to resubscribe customer.', errorSnackConfig as any);
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function clearAllScheduledWorkflows(id: string) {
  return async () => {
    try {
      await axios.delete(`/customers/${id}/clearAllScheduledWorkflows`);
      enqueueSnackbar('Scheduled Workflows cleared Successfully.', succesSnackConfig as any);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to cleared Scheduled Workflows.', errorSnackConfig as any);
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function updateReadStatus(conversationId: string) {
  return async () => {
    try {
      const response = await axios.post(`/conversations/updateReadStatus/${conversationId}`);
      return response.data;
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to update messgae status.', errorSnackConfig as any);
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function addGroupParticipants(groupId: string, conversationIds: string[]) {
  return async () => {
    try {
      await axios.put(`/group/${groupId}/addConversations`, { conversationIds });
      enqueueSnackbar('Participants added successfully.', succesSnackConfig as any);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to add Participants!', errorSnackConfig as any);
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function removeGroupParticipant(groupId: string, conversationId: string) {
  return async () => {
    try {
      await axios.delete(`/group/${groupId}/removeConversation/${conversationId}`);
      enqueueSnackbar('Participant removed successfully.', succesSnackConfig as any);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to removed Participant!', errorSnackConfig as any);
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getConversationById(id: string) {
  return async () => {
    try {
      const response = await axios.get(`/conversations/${id}`);
      return response;
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to fetch conversation!', errorSnackConfig as any);
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function scheduleGroupMessage(data: any) {
  return async () => {
    try {
      return await axios.post('/twilio/scheduleGroupMessage', data);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to send message.', errorSnackConfig as any);
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function deleteScheduledMessage(id: string) {
  return async () => {
    try {
      return await axios.delete(`/group/scheduledMessage/${id}`);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to delete schedule message.', errorSnackConfig as any);
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function markConversationUnread(conversationId: string) {
  return async () => {
    try {
      await axios.put(`/conversations/${conversationId}/markUnread`);
      enqueueSnackbar('Updated successfully.', succesSnackConfig as any);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to update!', errorSnackConfig as any);
      dispatch(chat.actions.hasError(error));
    }
  };
}

export const { onMessageReceive, resetChatsState } = chat.actions;
