import { createContext, useEffect, useReducer, ReactElement } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project-imports
import Loader from 'components/Loader';
import axios from 'utils/axios';
import { KeyedObject } from 'types/root';
import { AuthProps, JWTContextType } from 'types/auth';
import { enqueueSnackbar } from 'notistack';
import { errorSnackConfig, succesSnackConfig } from '../config';
import useLocalStorage from 'hooks/useLocalStorage';
import { SESSION_KEYS } from 'data/constant';

const chance = new Chance();

type SessionKeyType = (typeof SESSION_KEYS)[number];
const parseKeys: Array<SessionKeyType> = ['roleData', 'userData'];

// constant
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const verifyToken: (st: string) => boolean = (idToken) => {
  if (!idToken) {
    return false;
  }
  const decoded: KeyedObject = jwtDecode(idToken);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (sessionData: Array<{ key: SessionKeyType; value: string }>) => {
  sessionData.forEach(({ key, value }) => {
    if (key && value) {
      localStorage.setItem(key, parseKeys.includes(key) ? JSON.stringify(value || '') : value);
      if (key === 'idToken') {
        axios.defaults.headers.common.Authorization = `Bearer ${value}`;
      }
    }
  });
};

const removeSession = () => {
  SESSION_KEYS.map((key) => localStorage.removeItem(key));
  delete axios.defaults.headers.common.Authorization;
};

export const getSession = (): Record<SessionKeyType, any> => {
  return Object.assign(
    {},
    ...SESSION_KEYS.map((key) => ({
      [key]: parseKeys.includes(key) ? JSON.parse(localStorage.getItem(key) ?? '{}') : localStorage.getItem(key) ?? ''
    }))
  );
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [orgId, setOrganizationId] = useLocalStorage('organizationId');
  const [locationId, setLocationId] = useLocalStorage('locationId');

  useEffect(() => {
    const init = async () => {
      try {
        const { idToken, roleData, userData } = getSession();
        if (idToken && verifyToken(idToken)) {
          const decodedData: any = jwtDecode(idToken);
          setSession([
            { key: 'idToken', value: idToken },
            { key: 'roleData', value: roleData },
            { key: 'userData', value: userData }
          ]);
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: {
                ...decodedData,
                isSuperAdmin: decodedData?.isSuperAdmin === 'true',
                roles: decodedData?.isSuperAdmin === 'true' ? ['super-admin'] : [roleData?.[0]?.role || 'user'],
                rolesData: roleData,
                locationIds: decodedData.locationIds ?? '',
                organizationIds: decodedData.organizationIds ?? '',
                username: decodedData['cognito:username'] ?? '',
                userData
              }
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const login = async (username: string, password: string) => {
    try {
      if (orgId) setOrganizationId('');
      if (locationId) setLocationId('');
      const response = await axios.post('/auth/login', { username, password });
      const { IdToken, AccessToken } = response.data.payload?.tokens;
      const { roleData } = response.data.payload;
      const decodedData: any = jwtDecode(IdToken);
      setSession([
        { key: 'idToken', value: IdToken },
        { key: 'roleData', value: roleData },
        { key: 'accessToken', value: AccessToken },
        { key: 'userData', value: roleData?.[0]?.user }
      ]);
      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: true,
          user: {
            ...decodedData,
            isSuperAdmin: decodedData?.isSuperAdmin === 'true',
            roles: decodedData?.isSuperAdmin === 'true' ? ['super-admin'] : [roleData?.[0]?.role || 'user'],
            rolesData: roleData,
            userData: roleData?.[0]?.user,
            locationIds: decodedData.locationIds ?? '',
            organizationIds: decodedData.organizationIds ?? '',
            username: decodedData['cognito:username'] ?? ''
          }
        }
      });
    } catch (err: any) {
      enqueueSnackbar(err.error?.message ?? 'Failed to Login.', errorSnackConfig as any);
    }
  };

  const changePassword = async (formData: any) => {
    try {
      await axios.post('/auth/change-password', formData);
    } catch (err: any) {
      enqueueSnackbar(err.error?.message ?? 'Failed to Login.', errorSnackConfig as any);
    }
  };

  const updateUserAccountData = async (userId: string, formData: any) => {
    try {
      const response = await axios.put(`/users/${userId}`, formData);
      setSession([{ key: 'userData', value: response?.data?.payload }]);
      if (response?.data?.payload) {
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user: {
              ...(state.user as any),
              userData: response?.data?.payload as any
            }
          }
        });
      }

      enqueueSnackbar('Account details updated successfully', succesSnackConfig as any);
    } catch (err: any) {
      enqueueSnackbar(err.error?.message ?? 'Failed to update Account Details.', errorSnackConfig as any);
    }
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    // todo: this flow need to be recode as it not verified
    try {
      const id = chance.bb_pin();
      const response = await axios.post('/api/account/register', {
        id,
        email,
        password,
        firstName,
        lastName
      });
      let users = response.data;

      if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
        const localUsers = window.localStorage.getItem('users');
        users = [
          ...JSON.parse(localUsers!),
          {
            id,
            email,
            password,
            name: `${firstName} ${lastName}`
          }
        ];
      }

      window.localStorage.setItem('users', JSON.stringify(users));
    } catch (err: any) {
      enqueueSnackbar(err.error?.message ?? 'Failed to Register.', errorSnackConfig as any);
    }
  };

  const logout = () => {
    removeSession();
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async (data: any) => {
    try {
      await axios.post('/auth/resetPassword', data);
      enqueueSnackbar('Password Reset Successfully', succesSnackConfig as any);
    } catch (err: any) {
      enqueueSnackbar(err.error?.message ?? 'Failed to reset Password.', errorSnackConfig as any);
    }
  };

  const validateToken = async (token: string) => {
    try {
      const response = await axios.post(`/auth/validateToken/${token}`);
      return response?.data;
    } catch (err: any) {
      enqueueSnackbar(err.error?.message ?? 'Invalid Token', errorSnackConfig as any);
    }
  };

  const setPassword = async (data: any) => {
    try {
      await axios.post('/auth/setPassword', data);
      enqueueSnackbar('Password set successfully', succesSnackConfig as any);
    } catch (err: any) {
      enqueueSnackbar(err.error?.message ?? 'Failed to set Password.', errorSnackConfig as any);
    }
  };

  const forgotPassword = async (data: any) => {
    try {
      await axios.post('/auth/forgetPassword', { email: data });
      enqueueSnackbar('Check mail for forgot password link', succesSnackConfig as any);
      enqueueSnackbar('Password Forgot Successfully', succesSnackConfig as any);
    } catch (err: any) {
      enqueueSnackbar(err.error?.message ?? 'Failed to Forgot Password.', errorSnackConfig as any);
    }
  };

  const updateProfile = () => {};

  const hanldeSetUserRole = async (role: string) => {
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user: {
          ...state.user,
          roles: [role]
        }
      }
    });
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        resetPassword,
        validateToken,
        setPassword,
        forgotPassword,
        updateProfile,
        hanldeSetUserRole,
        changePassword,
        updateUserAccountData
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
