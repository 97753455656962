// material-ui
import { Badge } from '@mui/material';

// project-imports
// import AvatarStatus from './AvatarStatus';
import { UserProfile } from 'types/user-profile';
import Avatar from 'components/@extended/Avatar';

// assets
// const avatarImage = require.context('assets/images/users', true);

// ==============================|| CHAT - USER AVATAR WITH STATUS ICON ||============================== //

interface UserAvatarProps {
  user: UserProfile;
  src?: string;
}

const UserAvatar = ({ user, src }: UserAvatarProps) => (
  <Badge
    overlap="circular"
    // badgeContent={<AvatarStatus status={user.online_status!} />}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    sx={{
      textTransform: 'uppercase',
      '& .MuiBox-root': { width: 6, height: 6 },
      padding: 0,
      minWidth: 12,
      '& svg': { background: '#fff', borderRadius: '50%' }
    }}
  >
    {src ? (
      <Avatar alt={user.name} src={src}></Avatar>
    ) : (
      <Avatar alt={user.name}>
        {user.name?.split(' ')[0][0]}
        {user.name?.split(' ')[1] && user.name?.split(' ')[1][0]}
      </Avatar>
    )}
  </Badge>
);

export default UserAvatar;
