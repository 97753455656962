import { lazy } from 'react';

// project-imports
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { ChatProvider } from 'contexts/ChatContext';
import { RolePagesAccess } from 'data/constant';

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/error/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/error/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction/under-construction')));

// render - Home
const Dashboard = Loadable(lazy(() => import('pages/dashboard/dashboard')));
const Workflow = Loadable(lazy(() => import('pages/workflows')));
const SendWorkflows = Loadable(lazy(() => import('pages/workflows/send')));
const WorkflowOutcomes = Loadable(lazy(() => import('pages/reports/outcomes')));
const OutcomeWorkflowAnalytics = Loadable(lazy(() => import('pages/reports/outcomeDetailAnalytics')));
const AppChat = Loadable(lazy(() => import('pages/chat/chat')));
const Conversation = Loadable(lazy(() => import('pages/chat/conversation')));

// render - Super Admin
const OrganizationList = Loadable(lazy(() => import('pages/organization/organization-list')));
const OrganizationOnboard = Loadable(lazy(() => import('pages/organization/organization-onboard')));

// render - Profiles
const OrganizationProfile = Loadable(lazy(() => import('pages/profiles/organization')));
const OrganizationTabBilling = Loadable(lazy(() => import('sections/apps/profiles/organization/payments/TabBilling')));
const OrganizationTabUsers = Loadable(lazy(() => import('sections/apps/profiles/organization/TabUsers')));
const OrganizationTabLocations = Loadable(lazy(() => import('sections/apps/profiles/organization/TabLocations')));
const OrganizationWebchat = Loadable(lazy(() => import('sections/apps/profiles/organization/Webchat')));
const ProfileSettings = Loadable(lazy(() => import('pages/profiles/settings')));
const NotificationSetting = Loadable(lazy(() => import('sections/apps/profiles/organization/NotificationSetting')));
const MyAccount = Loadable(lazy(() => import('sections/apps/profiles/organization/MyAccount')));
const ChangePassword = Loadable(lazy(() => import('sections/apps/profiles/organization/ChangePassword')));

// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          element: <Dashboard />,
          allowedRoles: RolePagesAccess.dashboard
        },
        {
          path: 'chat',
          element: (
            <ChatProvider>
              <AppChat />
            </ChatProvider>
          ),
          allowedRoles: RolePagesAccess.chat
        },
        {
          path: 'reports/outcomes',
          element: <WorkflowOutcomes />,
          allowedRoles: ['user', 'super-admin', 'admin']
        },
        {
          path: 'reports/outcomes/:workflowId',
          element: <OutcomeWorkflowAnalytics />,
          allowedRoles: ['user', 'super-admin', 'admin']
        },
        {
          path: 'workflows/send',
          element: <SendWorkflows />,
          allowedRoles: ['user', 'super-admin', 'admin']
        },
        {
          path: 'chat/:locationId',
          element: <Conversation />,
          allowedRoles: RolePagesAccess.chat
        },
        {
          path: 'workflows',
          children: [
            {
              path: ':id',
              element: <Workflow />,
              allowedRoles: ['user', 'super-admin', 'admin']
            }
          ]
        },
        {
          path: 'organization',
          allowedRoles: RolePagesAccess.organization,
          children: [
            {
              path: 'list',
              element: <OrganizationList />,
              allowedRoles: RolePagesAccess.organization
            },
            {
              path: 'onboard',
              element: <OrganizationOnboard />,
              allowedRoles: RolePagesAccess.organization
            }
          ]
        },
        {
          path: 'profiles',
          allowedRoles: RolePagesAccess.profiles,
          children: [
            {
              path: 'organization',
              element: <OrganizationProfile />,
              allowedRoles: RolePagesAccess.profiles_organization,
              children: [
                {
                  path: 'users',
                  element: <OrganizationTabUsers />,
                  allowedRoles: RolePagesAccess.profiles_organization_users
                },
                {
                  path: 'locations',
                  element: <OrganizationTabLocations />,
                  allowedRoles: RolePagesAccess.profiles_organization_locations
                },
                {
                  path: 'webchat',
                  element: <OrganizationWebchat />,
                  allowedRoles: RolePagesAccess.profiles_organization_locations
                },
                {
                  path: 'billing',
                  element: <OrganizationTabBilling />,
                  allowedRoles: RolePagesAccess.profiles_organization_billing
                }
              ]
            },
            {
              path: 'settings',
              element: <ProfileSettings />,
              allowedRoles: RolePagesAccess.settings,
              children: [
                {
                  path: 'notification',
                  element: <NotificationSetting />,
                  allowedRoles: RolePagesAccess.settings_notification
                },
                {
                  path: 'change-password',
                  element: <ChangePassword />,
                  allowedRoles: RolePagesAccess.changePassword
                },
                {
                  path: 'account',
                  element: <MyAccount />,
                  allowedRoles: RolePagesAccess.account
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      allowedRoles: ['user', 'super-admin', 'admin'],
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        }
      ]
    }
  ]
};

export default MainRoutes;
