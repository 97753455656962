// material-ui
import { Theme } from '@mui/material/styles';

// types
import { ColorProps } from 'types/extended';

// ==============================|| CUSTOM FUNCTION - COLORS ||============================== //

const getColors = (theme: Theme, color?: ColorProps) => {
  switch (color!) {
    case 'secondary':
      return theme.palette.secondary;
    case 'error':
      return theme.palette.error;
    case 'warning':
      return theme.palette.warning;
    case 'info':
      return theme.palette.info;
    case 'success':
      return theme.palette.success;
    default:
      return theme.palette.primary;
  }
};

export const getStatusDotColors = (theme: any, color?: ColorProps) => {
  switch (color!) {
    case 'undetermined':
      return theme.palette.info.undetermined;
    case 'high':
    case 'ASI-4':
      return theme.palette.info.high;
    case 'unresponsive':
      return theme.palette.info.unresponsive;
    case 'veryHigh':
    case 'ASI-5':
      return theme.palette.info.veryHigh;
    case 'moderate':
    case 'PSI-3':
      return theme.palette.info.moderate;
    case 'info':
      return theme.palette.info;
    case 'veryLow':
    case 'NSI-1':
      return theme.palette.info.veryLow;
    case 'low':
    case 'PSI-2':
      return theme.palette.info.low;
    case 'imminent':
      return theme.palette.info.imminent;
    case 'error':
      return theme.palette.error.main;
    case 'totalCompletionCount':
      return theme.palette.info.veryLow;
    case 'partialCompletionCount':
      return theme.palette.info.moderate;
    case 'unresponsiveCount':
      return theme.palette.info.unresponsive;
    case 'partialAndCompletedCount':
      return theme.palette.error.main;
    default:
      return theme.palette.primary;
  }
};
export default getColors;
