// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// types
import { OrganizationStateProps } from 'types/organization';
import { errorSnackConfig, succesSnackConfig } from '../../config';
import { enqueueSnackbar } from 'notistack';
import { TypeUser } from 'types/auth';
import { TimeZones } from 'utils';

const initialState: OrganizationStateProps = {
  error: null,
  loading: false,
  organizations: [],
  locations: [],
  selectedOrg: null,
  selectedLocation: null,
  selectedLocationDetails: null,
  currentTimezone: 'America/Los_Angeles',
  addingUser: false,
  updateUser: false,
  users: [],
  usersCount: 0,
  loadingUsers: false
};

// ==============================|| SLICE - CHAT ||============================== //

const organization = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
      state.addingUser = false;
      state.loading = false;
      state.loadingUsers = false;
    },
    getOrganization(state) {
      state.loading = true;
    },
    setSelectedOrgId(state, action) {
      state.selectedOrg = action.payload;
    },
    getLocationByOrgId(state, action) {
      state.loading = true;
      state.selectedOrg = action.payload;
      state.locations = [];
    },
    setSelectedLocation(state, action) {
      state.selectedLocation = action.payload?.id;
      state.selectedLocationDetails = action.payload;
      state.currentTimezone = TimeZones[(action.payload?.timeZone as keyof typeof TimeZones) || 'PacificStandardTime'];
    },
    getOrganizationSuccess(state, action) {
      state.organizations = action.payload;
      state.loading = false;
    },
    getOrganizationsSuccess(state, action) {
      state.organizations = action.payload;
    },
    addUser(state) {
      state.addingUser = true;
    },
    addUserSuccess(state) {
      state.addingUser = false;
    },
    getUsers(state) {
      state.loadingUsers = true;
    },
    getUsersByLoation(state) {
      state.loadingUsers = true;
      state.users = [];
    },
    getUsersSuccess(state, action) {
      state.loadingUsers = false;
      state.users = action.payload.users ?? [];
      state.usersCount = action.payload.count;
    },
    getLocationByOrgIdSuccess(state, action) {
      state.loading = false;
      state.locations = action.payload;
    },
    updaeUser(state) {
      state.updateUser = true;
    },
    updaeUserSuccess(state) {
      state.updateUser = false;
    },
    addLocation(state) {
      state.addingUser = true;
    },
    addLocationSuccess(state) {
      state.addingUser = false;
    },
    resetOrganizationState(state) {
      state.error = null;
      state.loading = false;
      state.organizations = [];
      state.locations = [];
      state.selectedOrg = null;
      state.selectedLocation = null;
    }
  }
});

// Reducer
export default organization.reducer;
export const { getOrganizationSuccess, setSelectedOrgId } = organization.actions;

export function getOrganizations() {
  return async () => {
    try {
      dispatch(organization.actions.getOrganization());
      const response = await axios.get('/organizations');
      dispatch(organization.actions.getOrganizationSuccess(response.data.payload?.organizations || []));
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to fetch organizations.', errorSnackConfig as any);
      dispatch(organization.actions.hasError(error));
    }
  };
}

export function getOrganizationById(orgId: string) {
  return async () => {
    try {
      dispatch(organization.actions.getOrganization());
      const response = await axios.get(`/organizations/${orgId}`);
      dispatch(organization.actions.getOrganizationSuccess([response.data.payload] || []));
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to fetch organizations.', errorSnackConfig as any);
      dispatch(organization.actions.hasError(error));
    }
  };
}

export function getLocationByOrgId(orgId: string) {
  return async () => {
    try {
      dispatch(organization.actions.getLocationByOrgId(orgId));
      const response = await axios.get(`/organizations/${orgId}/locations`);
      if (response.data?.payload?.length === 0) {
        enqueueSnackbar('There is no location found for this organization. Contact Embrk Team support@embrk.com', errorSnackConfig as any);
      }
      dispatch(organization.actions.getLocationByOrgIdSuccess(response.data?.payload || []));
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to fetch locations.', errorSnackConfig as any);
      dispatch(organization.actions.hasError(error));
    }
  };
}

export function insertOrganization(organization: any) {
  return async () => {
    try {
      dispatch(organization.actions.setLoading());
      await axios.post('/api/organization/insert', organization);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to add organization.', errorSnackConfig as any);
      dispatch(organization.actions.hasError(error));
    }
  };
}

export function getAllUsers() {
  return async () => {
    try {
      const response = await axios.get('/users');
      dispatch(organization.actions.getUsersSuccess(response.data.payload));
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to fetch users.', errorSnackConfig as any);
      dispatch(organization.actions.hasError(error));
    }
  };
}

export function getUsersByLocation({ locationId, offset = 0, limit = 5, order = JSON.stringify({ firstName: 'DESC' }) }: any) {
  return async () => {
    try {
      dispatch(organization.actions.getUsersByLoation());
      const response = await axios.get(`/users?locationId=${locationId}&offset=${offset}&limit=${limit}&order=${order}`);
      dispatch(organization.actions.getUsersSuccess(response.data.payload));
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to fetch users.', errorSnackConfig as any);
      dispatch(organization.actions.hasError(error));
    }
  };
}

export function addUser(body: TypeUser) {
  return async () => {
    try {
      dispatch(organization.actions.addUser());
      await axios.post('/users', body);
      dispatch(organization.actions.addUserSuccess());
      enqueueSnackbar('User Added Successfully.', succesSnackConfig as any);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to add user.', errorSnackConfig as any);
      dispatch(organization.actions.hasError(error));
      throw error;
    }
  };
}

export function getUser(userId: string) {
  return async () => {
    try {
      await axios.get(`/users/${userId}`);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to fetch user.', errorSnackConfig as any);
      dispatch(organization.actions.hasError(error));
      throw error;
    }
  };
}

export function updateUser(body: TypeUser, id: string) {
  return async () => {
    try {
      dispatch(organization.actions.updaeUser());
      await axios.put(`/users/${id}`, body);
      dispatch(organization.actions.updaeUserSuccess());
      enqueueSnackbar('User Updated Successfully.', succesSnackConfig as any);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to update user.', errorSnackConfig as any);
      dispatch(organization.actions.hasError(error));
      throw error;
    }
  };
}

export function addLocation(body: TypeUser) {
  return async () => {
    try {
      dispatch(organization.actions.addLocation());
      await axios.post('/locations', body);
      dispatch(organization.actions.addLocationSuccess());
      enqueueSnackbar('Location Added Successfully.', succesSnackConfig as any);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to add Location.', errorSnackConfig as any);
      dispatch(organization.actions.hasError(error));
      throw error;
    }
  };
}

export function updateLocation(body: TypeUser, locationId: string) {
  return async () => {
    try {
      await axios.put(`/locations/${locationId}`, body);
      enqueueSnackbar('Location Updated Successfully.', succesSnackConfig as any);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to Update Location.', errorSnackConfig as any);
      dispatch(organization.actions.hasError(error));
    }
  };
}

export function deleteUser({ userId, locationId, organizationId }: any) {
  return async () => {
    try {
      await axios.delete(`/users/${userId}?locationId=${locationId}&organizationId=${organizationId}`);
      enqueueSnackbar('User Deleted Successfully.', succesSnackConfig as any);
    } catch (error: any) {
      enqueueSnackbar(error.error?.message ?? 'Failed to Deleted user.', errorSnackConfig as any);
    }
  };
}

export const { setSelectedLocation, resetOrganizationState, getLocationByOrgIdSuccess } = organization.actions;
